import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faDollarSign } from '@fortawesome/free-solid-svg-icons';

import { formatWithCommas } from 'helpers/formatters/number_formats';
import Controls from './controls';
import { isNil } from 'lodash';

const extractInt = (intString) => {
  const sanitizedNumberString = intString.replace(/[^0-9.]/g, '');
  return sanitizedNumberString || sanitizedNumberString === '0' ? Math.max(parseInt(sanitizedNumberString), 0) : '';
};

export default function Portfolio({ profile, onBack, onNext, onValueChange }) {
  const submit = (e) => {
    e.preventDefault();
    onNext();
  };

  const portfolioValue = profile.estimatedPortfolioValue;
  const disabled =
    isNil(profile.numberOfAssets) || profile.numberOfAssets === '' ||
    isNil(portfolioValue) || portfolioValue === '';

  return (
    <form onSubmit={submit}>
      <div className="mb-2">
        <FontAwesomeIcon icon={faBuilding} size="2x" />
      </div>
      <h5 className="mb-4">Tell us about your portfolio</h5>

      <div className="mb-3">
        <Form.Label>Number of properties under management *</Form.Label>
        <Form.Control
          autoFocus
          min="0"
          placeholder="Properties under management"
          type="number"
          value={profile.numberOfAssets}
          onChange={e => onValueChange('numberOfAssets', extractInt(e.target.value))}
        />
      </div>

      <div className="mb-3">
        <Form.Label>Estimated portfolio value *</Form.Label>
        <div className="with-icon">
          <FontAwesomeIcon className="icon text-primary" icon={faDollarSign} />
          <Form.Control
            min="0"
            placeholder="Estimated portfolio value"
            value={portfolioValue ? formatWithCommas(portfolioValue) : portfolioValue}
            onChange={e => onValueChange('estimatedPortfolioValue', extractInt(e.target.value))}
          />
        </div>
      </div>
      <Controls
        disabled={disabled}
        showNext
        onBack={onBack}
      />
    </form>
  );
}

Portfolio.propTypes = {
  profile: PropTypes.shape({
    companyName: PropTypes.string
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired
};
