import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Col, Container, Row } from 'react-bootstrap-5';
import { isEmpty } from 'lodash';

import Intro from './steps/intro';
import Company from './steps/company';
import Yourself from './steps/yourself';
import Portfolio from './steps/portfolio';
import Investment from './steps/investment';
import InvestmentSearch from './steps/investment_search';
import Success from './steps/success';
import Bar from './bar';

import { put, post } from 'helpers/api';
import { saveCriteria } from 'bundles/connect/helpers/local_storage';
import { locationsAttributesFromRegionNames } from 'bundles/connect/helpers/geo_json';

export default function Completion({
  certifications,
  existingProfile,
  investorTypeOptions,
  selfReportedSourceOptions
}) {
  const [profile, setProfile] = useState(existingProfile || {});
  const [digestEmail, setDigestEmail] = useState(true);
  const [step, setStep] = useState(0);
  const [saved, setSaved] = useState(false);

  const savedSearchAttrs = (profile) => {
    const {
      betaUser,
      is1031,
      expirationDate1031,
      investmentInvestmentTypes,
      investmentPrimaryPropertyTypes,
      investmentRegions
    } = profile;
    const criteria = { betaUser };

    if (!isEmpty(investmentPrimaryPropertyTypes)) criteria.assetClassIds = investmentPrimaryPropertyTypes;
    if (!isEmpty(investmentInvestmentTypes)) criteria.investmentTypeIds = investmentInvestmentTypes;

    const regions = investmentRegions?.filter(x => x) || [];
    if (regions.includes('Entire US') || regions.length == 0) {
      criteria.name = 'Entire US';
    } else if (regions.length > 0) {
      criteria.name = regions.length > 1 ? 'Multiple Regions' : regions[0];
      criteria.locationsAttributes = locationsAttributesFromRegionNames(regions);
    }

    if (is1031) {
      criteria.is_1031 = true;
      criteria.expiration_date_1031 = expirationDate1031;
    }

    return criteria;
  };

  const onSave = () => {
    setSaved(true);
    // the put helper does not correctly snake case the expiration_date_1031 attribute
    const criteria = savedSearchAttrs(profile);
    const profileData = {
      ...profile,
      expiration_date_1031: profile.expirationDate1031,
      notification_preference_attributes: { matches_digest_email: digestEmail }
    };
    saveCriteria({ criteria });

    const networkPromises = [put('/connect/profile', { profile: profileData, source: 'profile_completion' })];
    if (digestEmail) networkPromises.push(post('/connect/search/save_search', { set: criteria }));

    return Promise.all(networkPromises).then(() => setStep(totalSteps - 1));
  };

  const onValueChange = (key, val) => setProfile({ ...profile, [key]: val });
  const nextStep = () => setStep(step + 1);
  const lastStep = () => setStep(step - 1);

  const brokerSteps = () => {
    switch (step) {
    case 0:
      return <Intro onNext={nextStep} />;
    case 1:
      return <Company profile={profile} onBack={lastStep} onNext={nextStep} onValueChange={onValueChange} />;
    case 2:
      return (
        <Yourself
          profile={profile}
          selfReportedSourceOptions={selfReportedSourceOptions}
          onBack={lastStep}
          onSave={onSave}
          onValueChange={onValueChange}
        />
      );
    case 3:
      return <Success />;
    default:
      return;
    }
  };

  const investorSteps = () => {
    switch (step) {
    case 0:
      return <Intro onNext={nextStep} />;
    case 1:
      return <Company profile={profile} onBack={lastStep} onNext={nextStep} onValueChange={onValueChange} />;
    case 2:
      return (
        <Yourself
          certifications={certifications}
          investorTypeOptions={investorTypeOptions}
          profile={profile}
          selfReportedSourceOptions={selfReportedSourceOptions}
          onBack={lastStep}
          onNext={nextStep}
          onValueChange={onValueChange}
        />
      );
    case 3:
      return <Portfolio profile={profile} onBack={lastStep} onNext={nextStep} onValueChange={onValueChange} />;
    case 4:
      return <Investment profile={profile} onBack={lastStep} onNext={nextStep} onValueChange={onValueChange} />;
    case 5:
      return (
        <InvestmentSearch
          digestEmail={digestEmail}
          profile={profile}
          onBack={lastStep}
          onSave={onSave}
          onSetDigestEmail={setDigestEmail}
          onValueChange={onValueChange}
        />
      );
    case 6:
      return <Success/>;
    default:
      return;
    }
  };

  let stepComponent, totalSteps, stepsLabels;

  if (profile.userType === 'broker') {
    stepComponent = brokerSteps();
    totalSteps = 4;
    stepsLabels = ['Company', 'Job'];
  } else {
    stepComponent = investorSteps();
    totalSteps = 7;
    stepsLabels = ['Company', 'Job', 'Portfolio', 'Investment Search'];
  }

  const showSidebar = step > 0 && step < totalSteps - 1;

  return (
    <Container className="position-absolute w-100 h-100" fluid>
      <Row className="h-100">
        <Col className="overflow-auto h-100" style={{ '--animate-duration': '0.3s' }}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              classNames={{
                enter: 'animate__animated',
                enterActive: 'animate__fadeIn',
                exit: 'animate__animated',
                exitActive: 'animate__fadeOut'
              }}
              key={step}
              timeout={300}
            >
              <Row className="justify-content-center">
                <Col lg={9} md={9} xl={8} xxl={6}>
                  <div className="py-5">
                    {stepComponent}
                  </div>
                </Col>
              </Row>
            </CSSTransition>
          </SwitchTransition>
        </Col>
        <Col
          className={`flex-column justify-content-center d-none d-md-flex vh-100 ${showSidebar ? 'border-start' : ''}`}
          style={{ width: '300px' }}
          xs="auto"
        >
          {showSidebar && (
            <Bar
              labels={stepsLabels}
              saved={saved}
              step={step}
              total={totalSteps}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}

Completion.propTypes = {
  certifications: PropTypes.array.isRequired,
  existingProfile: PropTypes.shape({
  }).isRequired,
  investorTypeOptions: PropTypes.object.isRequired,
  selfReportedSourceOptions: PropTypes.object.isRequired
};
