import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap-5';
import { offMarketPropertyUrl } from '../../helpers/connect_api';

export default function Overview({ insightsPropertyId }) {
  return (
    <turbo-frame class="h-100" id="match_details" src={offMarketPropertyUrl(insightsPropertyId, true)}>
      <div style={{ minHeight: '500px' }}>
        <div className="px-3 py-5">
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      </div>
    </turbo-frame>
  );
}

Overview.propTypes = {
  insightsPropertyId: PropTypes.number.isRequired
};
