import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';
import DatePicker from 'react-datepicker';

export default function MeetingForm({ activity, onUpdate }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    if (activity.startTime !== null && activity.startTime !== undefined) {
      setStartDate(new Date(activity.startTime));
    }

    if (activity.endTime !== null && activity.endTime !== undefined) {
      setEndDate(new Date(activity.endTime));
    }
  }, []);

  const onStartDateUpdate = (date) => {
    setStartDate(date);
    onUpdate({ ...activity, startTime: date });
  };

  const onEndDateUpdate = (date) => {
    setEndDate(date);
    onUpdate({ ...activity, endTime: date });
  };

  return (
    <div className="d-flex flex-column gap-3">
      <Form.Group>
        <Form.Label>Location</Form.Label>
        <Form.Control
          type="text"
          value={activity.location}
          onChange={e => onUpdate({ ...activity, location: e.target.value })}
        />
      </Form.Group>
      <Form.Group className="d-flex align-items-center gap-2">
        <Form.Label className="mb-0">Start Time</Form.Label>
        <DatePicker
          className="form-control"
          dateFormat="Pp"
          maxDate={endDate}
          selected={startDate}
          showTimeSelect
          onChange={date => onStartDateUpdate(date)}
        />
        <Form.Label className="mb-0">End Time</Form.Label>
        <DatePicker
          className="form-control"
          dateFormat="Pp"
          minDate={startDate}
          selected={endDate}
          showTimeSelect
          onChange={date => onEndDateUpdate(date)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={activity.description}
          onChange={e => onUpdate({ ...activity, description: e.target.value })}
        />
      </Form.Group>
    </div>
  );
}

MeetingForm.propTypes = {
  activity: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
};
