import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap-5';

import ProfileInformation from './steps/profile_information';
import CountySelect from './steps/county_select';
import StepProgress from './step_progress';
import { fetchProfile, subscriptionStatus, updateProfile } from '../../helpers/connect_api';
import { pick } from 'lodash';

export default function CompletionNew({
  brokerSpecialtyOptions,
  counties,
  investorTypeOptions
}) {
  const [profile, setProfile] = useState({});
  const [step, setStep] = useState(1);
  const [saving, setSaving] = useState(false);
  const [profileReady, setProfileReady] = useState(false);
  const hasRegions = profile.regions?.length > 0;

  const onRegionChange = (id, key, val) => {
    setProfile((prevProfile) => {
      const regions = prevProfile.regions.map(region => region.id === id ? { ...region, [key]: val } : region);
      return { ...prevProfile, regions };
    });
  };
  const onValueChange = (key, val) => setProfile(prevProfile => ({ ...prevProfile, [key]: val }));

  const onSave = () => {
    setSaving(true);

    const params = pick(profile, ['companyIsSelf', 'companyName', 'countryId', 'city', 'state', 'investorTypes']);

    updateProfile({ ...params, regions_attributes: profile.regions }, 'profile_completion')
      .then(() => window.location.href = '/connect/search');
  };

  const onNextStep = () => {
    if (step < totalSteps) {
      nextStep();
    } else {
      onSave();
    }
  };

  useEffect(() => {
    // It may take a few seconds for revenue cat to trigger the webhook that sets up their account.
    // This polls for the status of the profile and will only continue when its ready.
    if (!profileReady) {
      const interval = setInterval(() => {
        subscriptionStatus().then((status) => {
          if (status === 'ready') {
            fetchProfile().then((profile) => {
              setProfile(profile);
              setProfileReady(true);
            });
          }
        });
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [profileReady]);

  const nextStep = () => setStep(step + 1);
  const lastStep = () => setStep(step - 1);

  const totalSteps = hasRegions ? 2 : 1;
  const stepLabels =
    [
      { title: 'Profile Information', subTitle: 'Tell us about yourself' },
      { title: 'County Select', subTitle: 'Select your preferred counties' }
    ];

  const steps = () => {
    switch (step) {
    case 1:
      return (
        <ProfileInformation
          brokerSpecialtyOptions={brokerSpecialtyOptions}
          investorTypeOptions={investorTypeOptions}
          profile={profile}
          saving={saving}
          showSave={totalSteps == 1}
          onSubmit={onNextStep}
          onValueChange={onValueChange}
        />
      );
    case 2:
      return (
        <CountySelect
          counties={counties}
          profile={profile}
          saving={saving}
          onBack={lastStep}
          onSave={onSave}
          onValueChange={onRegionChange}
        />
      );
    default:
      return;
    }
  };
  const stepComponent = steps();

  let rightColumnContent;

  if (profileReady) {
    rightColumnContent = (
      <Card>
        <Card.Body>
          {totalSteps > 1 && (
            <StepProgress labels={stepLabels} step={step} total={totalSteps} />
          )}
          <SwitchTransition mode="out-in">
            <CSSTransition
              classNames={{
                enter: 'animate__animated',
                enterActive: 'animate__fadeIn',
                exit: 'animate__animated',
                exitActive: 'animate__fadeOut'
              }}
              key={step}
              timeout={300}
            >
              {stepComponent}
            </CSSTransition>
          </SwitchTransition>
        </Card.Body>
      </Card>
    );
  } else {
    // Use a fake height to minimize the screen shifting when we display the actual content
    rightColumnContent = (
      <div className="text-center" style={{ minHeight: '628px' }}>
        <h6>Just a moment, we're setting up your account.</h6>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Container className="h-100 d-flex flex-column justify-content-center py-8">
      <Row className="justify-content-center gy-4">
        <Col className="text-center text-md-start" md={12} xl={6}>
          <div>
            <h1 className="text-gradient premium-signup-header fw-bolder d-inline-block">Welcome!</h1>
          </div>
          You're just a few steps away from upgrading your prospecting power.
          Please fill out the information below so we can tailor the experience for you.
        </Col>
        <Col md={12} xl={6}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              classNames={{
                enter: 'animate__animated',
                enterActive: 'animate__fadeIn',
                exit: 'animate__animated',
                exitActive: 'animate__zoomOut'
              }}
              key={profileReady ? 'intro' : 'steps'}
              timeout={600}
            >
              <div>
                {rightColumnContent}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </Col>
      </Row>
    </Container>
  );
}

CompletionNew.propTypes = {
  brokerSpecialtyOptions: PropTypes.object.isRequired,
  counties: PropTypes.object.isRequired,
  existingProfile: PropTypes.shape({}).isRequired,
  investorTypeOptions: PropTypes.object.isRequired
};
