import React from 'react';
import PropTypes from 'prop-types';

// Use this class alongside react-bootstrap Dropdown to make any node a toggle for a dropdown menu
class UnstyledDropdownToggle extends React.Component {

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
  }

  handleClick = (e) => {
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    return (
      <a aria-label="Dropdown Toggle" className={this.props.className} href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

export default UnstyledDropdownToggle;
