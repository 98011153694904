import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBuilding, faMagnifyingGlass, faUserTie } from '@fortawesome/pro-light-svg-icons';

export default function Intro({ onNext }) {
  return (
    <React.Fragment>
      <div className="mb-4 mt-4">
        <FontAwesomeIcon className="me-3 text-secondary" icon={faBriefcase} size="2x" />
        <FontAwesomeIcon className="me-3 text-secondary" icon={faUserTie} size="2x" />
        <FontAwesomeIcon className="me-3 text-secondary" icon={faBuilding} size="2x" />
        <FontAwesomeIcon className="me-3 text-secondary" icon={faMagnifyingGlass} size="2x" />
      </div>
      <h4 className="mb-5">
        <strong>You’re 2 minutes away</strong> from getting access to thousands of exclusive off market listings
      </h4>
      <div className="mb-5">
        <h5 className="fw-light mb-1">We just need a few more details to get started</h5>
        <div>
          <small>
            This information is used to improve your Buildout Connect experience,
            match you with brokers, and improve the likelihood of successful transactions.
          </small>
        </div>
      </div>

      <div>
        <Button size="lg" variant="secondary" onClick={onNext}>Tell us about yourself</Button>
      </div>
    </React.Fragment>
  );
}

Intro.propTypes = {
  onNext: PropTypes.func.isRequired
};
