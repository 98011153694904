import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap-5';
import { createNote } from '../../../helpers/connect_api';
import TrixEditor from 'shared/trix/trix_editor';

export default function NewNoteForm({ recordId, recordType, show, onCreate, onHide }) {
  const [body, setBody] = useState('');
  const [saving, setSaving] = useState(false);

  const handleSave = () => {
    createNote(recordId, recordType, body)
      .then((note) => {
        onCreate(note);
        onHide();
        setBody('');
      })
      .finally(() => setSaving(false));
  };

  if (!show) return null;

  return (
    <div className="d-flex flex-column gap-2">
      <TrixEditor id="new-note" name="body" onChange={setBody} />
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button disabled={saving} variant="muted" onClick={onHide}>Cancel</Button>
        </Col>
        <Col xs="auto">
          <Button disabled={saving} variant="secondary" onClick={handleSave}>Save</Button>
        </Col>
      </Row>
    </div>
  );
}

NewNoteForm.propTypes = {
  recordId: PropTypes.number.isRequired,
  recordType: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
};
