import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';
import {
  ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL, ACTIVITY_TYPE_OTHER,
  TASK_PRIORITY_HIGH, TASK_PRIORITY_LOW, TASK_PRIORITY_MEDIUM
} from '../../../constants';
import ContactInput from '../activity_forms/contact_input';
import DatePicker from 'react-datepicker';
import ButtonGroupRadio from '../activity_forms/button_group_radio';
import { faAngleDown, faAnglesUp, faEnvelope, faEquals, faPencil, faPhone } from '@fortawesome/pro-solid-svg-icons';

const TYPE_OPTIONS = [
  { icon: faPhone, label: 'Call', value: ACTIVITY_TYPE_CALL, },
  { icon: faEnvelope, label: 'Email', value: ACTIVITY_TYPE_EMAIL },
  { icon: faPencil, label: 'Other', value: ACTIVITY_TYPE_OTHER }
];

const PRIORITY_OPTIONS = [
  { icon: faAngleDown, label: 'Low', value: TASK_PRIORITY_LOW, },
  { icon: faEquals, label: 'Medium', value: TASK_PRIORITY_MEDIUM },
  { icon: faAnglesUp, label: 'High', value: TASK_PRIORITY_HIGH }
];

export default function TaskForm({ activity, showContactInput = true, onChange }) {
  const handleDueDateChange = useCallback((newDate) => {
    if (newDate) {
      const offset = newDate.getTimezoneOffset();
      const localDate = new Date(newDate.getTime() - (offset * 60 * 1000));
      onChange({ ...activity, dueDate: localDate.toISOString().split('T')[0] });
    } else {
      onChange({ ...activity, dueDate: undefined });
    }

    const dateString = newDate ? `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}` : undefined;
    onChange({ ...activity, dueDate: dateString });
  }, [activity]);

  return (
    <React.Fragment>
      <Form.Group>
        <Form.Label>Name *</Form.Label>
        <input
          className="form-control"
          placeholder="Enter name for task"
          value={activity.name}
          onChange={e => onChange({ ...activity, name: e.target.value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Task Type</Form.Label>
        <ButtonGroupRadio
          checked={activity.type}
          name="task_type"
          options={TYPE_OPTIONS}
          onChange={type => onChange({ ...activity, type })}
        />
      </Form.Group>
      {showContactInput && <ContactInput activity={activity} onChange={onChange} />}
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <textarea
          className="form-control"
          placeholder="Enter details..."
          value={activity.description}
          onChange={e => onChange({ ...activity, description: e.target.value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Due Date</Form.Label>
        <div>
          <DatePicker
            className="form-control"
            selected={activity.dueDate ? new Date(`${activity.dueDate} 00:00`) : undefined}
            wrapperClassName="d-block"
            onChange={handleDueDateChange}
          />
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Priority</Form.Label>
        <ButtonGroupRadio
          checked={activity.priority}
          name="priority"
          options={PRIORITY_OPTIONS}
          onChange={priority => onChange({ ...activity, priority })}
        />
      </Form.Group>
    </React.Fragment>
  );
}

TaskForm.propTypes = {
  activity: PropTypes.object.isRequired,
  showContactInput: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};
