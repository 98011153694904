import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap-5';
import { map, xor } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faApartment,
  faChairOffice,
  faFence,
  faFlag,
  faHotel,
  faIndustry,
  faMapLocation,
  faStore
} from '@fortawesome/pro-solid-svg-icons';

import { propertyTypeMappings } from 'helpers/property_types';
import { REGIONS } from 'bundles/connect/constants';
import Controls from './controls';

const PROPERTY_TYPE_ICON_MAP = {
  1: faChairOffice,
  2: faStore,
  3: faIndustry,
  5: faFence,
  6: faApartment,
  7: faFlag,
  8: faHotel
};

const INVESTMENT_TYPES = {
  0: 'Core',
  1: 'Core Plus',
  2: 'Value Add',
  3: 'Opportunistic',
  4: 'Distressed'
};

export default function Investment({ digestEmail, profile, onBack, onSave, onSetDigestEmail, onValueChange }) {
  const [saving, setSaving] = useState(false);
  const [showOtherRegionInput, setShowOtherRegionInput] = useState(!!profile.investmentRegionOther);

  const investmentPrimaryPropertyTypes = profile.investmentPrimaryPropertyTypes || [];
  const investmentInvestmentTypes = profile.investmentInvestmentTypes || [];
  const investmentRegions = profile.investmentRegions || [];

  const save = (e) => {
    e.preventDefault();
    setSaving(true);
    onSave().then(() => setSaving(false));
  };

  const toggleValue = (field, type) => {
    onValueChange(field, xor(profile[field] || [], [type]));
  };

  const saveDisabled = saving || investmentPrimaryPropertyTypes.length === 0 ||
    investmentInvestmentTypes.length === 0 ||
    (investmentRegions.length === 0 && !profile.investmentRegionOther);

  return (
    <form onSubmit={save}>
      <div className="mb-2">
        <FontAwesomeIcon icon={faMapLocation} size="2x" />
      </div>
      <h5 className="mb-4">Tell us about your typical investment preferences</h5>

      <div className="mb-4">
        <Form.Label className="m-0">What property types do you primarily invest in? *</Form.Label>
        <div className="mb-2">
          <small className="text-muted">Select at least one</small>
        </div>
        {map(propertyTypeMappings, (value, key) => {
          return (
            <div className="mb-2" key={key}>
              <Button
                className="text-start"
                style={{ minWidth: '200px' }}
                variant={investmentPrimaryPropertyTypes.includes(parseInt(key)) ? 'success' : 'outline-dark'}
                onClick={() => toggleValue('investmentPrimaryPropertyTypes', parseInt(key))}
              >
                <FontAwesomeIcon className="me-2" icon={PROPERTY_TYPE_ICON_MAP[key]} />
                {value}
              </Button>
            </div>
          );
        })}
      </div>

      <div className="mb-4">
        <Form.Label className="m-0">What investment types do you prefer? *</Form.Label>
        <div className="mb-2">
          <small className="text-muted">Select at least one</small>
        </div>
        {map(INVESTMENT_TYPES, (value, key) => {
          return (
            <div className="mb-2" key={key}>
              <Button
                className="text-start"
                style={{ minWidth: '200px' }}
                variant={investmentInvestmentTypes.includes(parseInt(key)) ? 'success' : 'outline-dark'}
                onClick={() => toggleValue('investmentInvestmentTypes', parseInt(key))}
              >
                {value}
              </Button>
            </div>
          );
        })}
      </div>

      <div className="mb-4">
        <Form.Label className="m-0">What regions do you primarily invest in? *</Form.Label>
        <div className="mb-2">
          <small className="text-muted">Select at least one</small>
        </div>
        <div className="mb-2">
          <Button
            className="text-start"
            style={{ minWidth: '200px' }}
            variant={investmentRegions.includes('Entire US') ? 'success' : 'outline-dark'}
            onClick={() => toggleValue('investmentRegions', 'Entire US')}
          >
            Entire US
          </Button>
        </div>
        {map(REGIONS, ({ label }) => {
          return (
            <div className="mb-2" key={label}>
              <Button
                className="text-start"
                style={{ minWidth: '200px' }}
                variant={investmentRegions.includes(label) ? 'success' : 'outline-dark'}
                onClick={() => toggleValue('investmentRegions', label)}
              >
                {label}
              </Button>
            </div>
          );
        })}
        <div className="mb-2">
          <Button
            className="text-start"
            style={{ minWidth: '200px' }}
            variant={showOtherRegionInput ? 'success' : 'outline-dark'}
            onClick={() => {
              if (showOtherRegionInput) onValueChange('investmentRegionOther', '');
              setShowOtherRegionInput(!showOtherRegionInput);
            }}
          >
            Other
          </Button>
        </div>
        {showOtherRegionInput && (
          <div>
            <Form.Control
              maxLength={255}
              placeholder="Enter your market / region"
              value={profile.investmentRegionOther || ''}
              onChange={e => onValueChange('investmentRegionOther', e.target.value)}
            />
          </div>
        )}
      </div>

      <div className="mb-4">
        <Form.Label className="m-0">Saved search digest</Form.Label>
        <div className="mb-2">
          <small className="text-muted">Enable saved search match notifications?</small>
        </div>
        <div>
          <Button
            className="text-start"
            style={{ minWidth: '200px' }}
            variant={digestEmail ? 'success' : 'outline-dark'}
            onClick={() => onSetDigestEmail(!digestEmail)}
          >
            {digestEmail ? 'Yes' : 'No'}
          </Button>
        </div>
      </div>

      <Controls
        disabled={saveDisabled}
        showSave
        onBack={onBack}
      />
    </form>
  );
}

Investment.propTypes = {
  digestEmail: PropTypes.bool,
  profile: PropTypes.shape({
    companyName: PropTypes.string
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSetDigestEmail: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired
};
