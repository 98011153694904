import React from 'react';
import PropTypes from 'prop-types';
import DataExportTemplateSettings from './template_settings';
import ExportList from './export_list';

export default function DataExportSettings({ columnOptions, exports, templates }) {

  return (
    <div>
      <ExportList exports={exports}/>
      <DataExportTemplateSettings columnOptions={columnOptions} templates={templates}/>
    </div>
  );
}

DataExportSettings.propTypes = {
  columnOptions: PropTypes.object,
  exports: PropTypes.array,
  templates: PropTypes.array
};
