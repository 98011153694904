import { get, post } from 'helpers/api';
import { trackEvent } from '../search/helpers/google_analytics_helpers';

export function llcLookup(propertyId) {
  trackEvent('connect_off_market_llc_lookup', { propertyId });
  return get('/connect/llc_lookup/insights_lookup', { params: { propertyId } });
}

export function fetchDocuments(companyId, jurisdictionCode) {
  trackEvent('connect_off_market_llc_document_fetch', { companyId, jurisdictionCode });
  return get(
    '/connect/llc_lookup/documents',
    { params: { companyId: companyId, jurisdictionCode: jurisdictionCode } }
  );
}

export function searchCompanies(search) {
  trackEvent('connect_off_market_llc_manual_search');
  return post('/connect/llc_lookup/search_companies', { search });
}

export function fetchCompanyDetails(companyId, jurisdictionCode) {
  trackEvent('connect_off_market_llc_company_details', { companyId, jurisdictionCode });
  return get(
    '/connect/llc_lookup/company_details',
    { params: { companyId: companyId, jurisdictionCode: jurisdictionCode } }
  );
}
