import React from 'react';
import { Button } from 'react-bootstrap-5';
import { faFaceParty } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Success() {
  const close = () => window.location.href = '/connect/search';

  React.useEffect(() => {
    setTimeout(close, 5000);
  }, []);

  return (
    <div className="text-center">
      <h5 className="my-4">Welcome to Buildout Connect!</h5>
      <FontAwesomeIcon className="text-pink" icon={faFaceParty} size="4x"/>
      <div className="my-4">
        Thanks for completing your investor profile.
        You now have access to thousands of exclusive off market listings.
        Start filtering to find listings that match what you're looking for!
      </div>
      <Button variant="secondary" onClick={close}>Get started!</Button>
    </div>
  );
}
