import { filter } from 'lodash';
import { REGIONS } from 'bundles/connect/constants';
import { v4 as uuid } from 'uuid';

export const locationsAttributesFromRegionNames = (regions) => {
  const regionData = filter(REGIONS, region => regions.includes(region.label));

  return locationsAttributesFromRegionData(regionData);
};

export const locationsAttributesFromRegionData = (regions) => {
  return regions.map(region => ({
    name: region.label,
    polygonSearch: true,
    polygonGeojson: region,
    id: uuid()
  }));
};
