import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, isEmpty } from 'lodash';

import { faMagnifyingGlass, faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildoutLogo } from 'helpers/custom_fontawesome_icons';

import { Button, Form, InputGroup, ListGroup, Overlay, Popover } from 'react-bootstrap-5';
import { searchContacts } from '../../../helpers/connect_api';
import { connect } from 'react-redux';
import { createOwnership } from '../store/actions/owners';
import CreateModal from './create_modal';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';

function AddContact({ insightsPropertyId, createOwnership }) {
  const [showOverlay, setShowOverlay] = useState();
  const [showCreateModal, setShowCreateModal] = useState();
  const [contacts, setContacts] = useState([]);
  const [contactCount, setContactCount] = useState(0);
  const [saving, setSaving] = useState(false);

  const inputRef = useRef(null);
  const triggerRef = useRef(null);
  const overlayId = Math.floor(Math.random() * 100000);

  useEffect(() => {
    if (showOverlay) {
      handleChange();
      setSaving(false);
    }
  }, [showOverlay]);

  const debouncedSearch = debounce((search) => {
    searchContacts(search).then((results) => {
      setContactCount(results.contacts.total);
      setContacts([...results.contacts.hits]);
    });
  }, 300);

  const handleChange = () => debouncedSearch(inputRef?.current?.value || '*');

  const handleCloseOverlay = () => {
    if (inputRef.current) inputRef.current.value = null;

    setShowOverlay(false);
  };

  const handleCreateContact = (contact) => {
    setShowCreateModal(false);
    setShowOverlay(true);
    handleAddOwner(contact);
  };

  const handleAddOwner = (contact) => {
    setSaving(true);
    createOwnership(insightsPropertyId, contact.id)
      .then(() => {
        setShowCreateModal(false);
        setShowOverlay(false);
      })
      .finally(() =>{
        setSaving(false);
      });
  };

  const handleShowCreateModal = (e) => {
    e.stopPropagation();

    handleCloseOverlay();
    setShowCreateModal(true);
  };


  const popover = (
    <Popover id={`add-contact-owner-popover-${overlayId}`} style={{ maxWidth: '500px', minWidth: '320px' }}>
      <Popover.Header className="bg-white border-bottom">
        <h6 className="fw-bolder m-0">Add Contact</h6>
      </Popover.Header>
      {saving && (
        <Popover.Body>
          <div className="text-primary text-center animate__animated animate__fadeIn" style={{ fontSize: '1rem' }}>
            <FontAwesomeIcon beatFade className="me-2" icon={faBuildoutLogo} />
            Saving...
          </div>
        </Popover.Body>
      )}
      {!saving && (
        <React.Fragment>
          <Popover.Body className="px-0 pt-2 pb-0">
            <Form.Group className="mb-2 px-3 pb-2 border-bottom">
              <Form.Label>Search your contacts</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faMagnifyingGlass}/>
                </InputGroup.Text>
                <Form.Control ref={inputRef} type="text" onChange={handleChange}/>
              </InputGroup>
            </Form.Group>

            {isEmpty(contacts) ? (
              <div className="py-2 px-3">No results found</div>
            ) : (
              <React.Fragment>
                <div className="px-3 fw-bolder text-muted">
                  {contactCount} Contacts
                </div>
                <ListGroup style={{ maxHeight: '200px', overflow: 'auto' }}>
                  {
                    contacts.map(contact => (
                      <ListGroup.Item
                        action
                        className="border-0"
                        key={contact.id}
                        onClick={() => handleAddOwner(contact)}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <FontAwesomeIcon icon={faCircleUser} />
                          <div>
                            {contact.name}
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))
                  }
                </ListGroup>
              </React.Fragment>
            )}
          </Popover.Body>
          <div className="border-top d-flex align-items-center justify-content-end p-2">
            <Button variant="secondary" onClick={handleShowCreateModal}>
              <FontAwesomeIcon className="me-2" icon={faUserPlus} size="sm" />
              Create Contact
            </Button>
          </div>
        </React.Fragment>
      )}
    </Popover>
  );

  return (
    <React.Fragment>
      <Overlay
        flip={true}
        placement="auto"
        rootClose
        show={showOverlay}
        target={triggerRef.current}
        onHide={() => setShowOverlay(false)}
      >
        {popover}
      </Overlay>
      
      <Button ref={triggerRef} variant="outline-secondary" onClick={() => setShowOverlay(true)}>
        <FontAwesomeIcon icon={faUserPlus} />
        Add Contact
      </Button>

      {showCreateModal && (
        <CreateModal
          onHide={() => setShowCreateModal(false)}
          onPushContact={handleCreateContact}
        />
      )}
    </React.Fragment>
  );
}

AddContact.propTypes = {
  createOwnership: PropTypes.func.isRequired,
  insightsPropertyId: PropTypes.number.isRequired
};

const mapStateToProps = ({ insightsPropertyId }) => ({ insightsPropertyId });
export default connect(mapStateToProps, { createOwnership })(AddContact);
