import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap-5';

export default function Bar({ labels, saved, step, total }) {
  const [progressBarHeight, setProgressBarHeight] = useState(0);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setProgressBarHeight(node.getBoundingClientRect().height);
    }
  }, []);

  let progressPercent;

  if (step === 0) {
    progressPercent = 0;
  } else if (saved) {
    progressPercent = 100;
  } else {
    progressPercent = ((step * 2) - 1) * (100 / total);
  }

  return (
    <div className="position-relative animate__animated animate__fadeIn" ref={measuredRef}>
      <ProgressBar
        className="position-absolute start-0"
        now={progressPercent}
        style={{
          transform: 'translate(19px, -50%) rotate(90deg)',
          transformOrigin: '0',
          width: `${progressBarHeight}px`,
          height: '10px'
        }}
        variant={step == total - 1 ? 'success' : 'primary'}
      />
      <div className="ps-10">
        {labels.map((label, index) => (
          <h5 className={step < index + 1 ? 'fw-light' : ''} key={index}>{label}</h5>
        ))}
      </div>
    </div>
  );
}

Bar.propTypes = {
  labels: PropTypes.array,
  saved: PropTypes.bool,
  step: PropTypes.number,
  total: PropTypes.number
};
