import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap-5';

import { saveSearch } from '../../store/actions/results';
import { showSaveSearchModal } from '../../store/actions/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCheck } from '@fortawesome/free-solid-svg-icons';
import CriteriaDecorator from '../../helpers/criteria_decorator';

const SAVED_SEARCHES_PATH = '/connect/saved_searches';

function SaveSearchModal({ criteria, offMarketSearch, saveSearch, show, showSaveSearchModal }) {
  const [name, setName] = useState();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const decoratedCriteria = new CriteriaDecorator(criteria, offMarketSearch);

  const save = () => {
    setSaving(true);
    saveSearch(name).then(() => {
      setSaving(false);
      setSaved(true);
    });
  };

  return (
    <Modal show={show} onHide={() => showSaveSearchModal(false)}>
      <Modal.Body className="p-10">
        {saved && (
          <React.Fragment>
            <h5 className="text-center text-success">
              <FontAwesomeIcon className="me-2" icon={faCheck} />
              Search Saved
            </h5>
            <div className="text-center">
              <a href={SAVED_SEARCHES_PATH} rel="noopener noreferrer" target="_blank">
                View your saved searches
                <FontAwesomeIcon className="ms-2" icon={faArrowUpRightFromSquare}/>
              </a>
            </div>
          </React.Fragment>
        )}
        {!saved && (
          <React.Fragment>
            <h5 className="text-center">
              Save this search
            </h5>
            <p className="my-6">
              {!offMarketSearch && 'Save your search and get instant notifications when new properties match.'}
              {offMarketSearch && 'Save your search and to easily resume your research at a later time.'}
              To edit or view existing saved searches, visit your{' '}
              <a href={SAVED_SEARCHES_PATH} rel="noopener noreferrer" target="_blank">saved searches page</a>.
            </p>
            <Form.Label>Your search criteria</Form.Label>
            <div className="pb-6">
              <Row className="g-2">
                {decoratedCriteria.filterSummary().map((filter) => {
                  return (
                    <Col key={filter.label} xs="auto">
                      <Badge bg="powder-blue" className="rounded-pill px-3 py-2">
                        {filter.label}
                      </Badge>
                    </Col>
                  );
                })}
              </Row>
            </div>
            <Form.Label>Search name</Form.Label>
            <div className="pb-6">
              <Form.Control
                placeholder={decoratedCriteria.name()}
                value={name || ''}
                onChange={e => setName(e.target.value)}
              />
            </div>

            <Button className="w-100" disabled={saving} variant="primary" onClick={save}>
              {saving ? 'Saving' : 'Save search'}
            </Button>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
}

SaveSearchModal.propTypes = {
  criteria: PropTypes.shape({
    assetClassIds: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  offMarketSearch: PropTypes.bool,
  saveSearch: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showSaveSearchModal: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria, ui }) => {
  return { criteria, show: ui.showSaveSearchModal, offMarketSearch: ui.offMarketSearch };
};

export default connect(mapStateToProps, { saveSearch, showSaveSearchModal })(SaveSearchModal);
