import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import configureStore from '../../call_list/store/configure';
import { Provider } from 'react-redux';
import OwnerList from './owner_list';

export default function Owners({ insightsPropertyId, trialUser }) {
  const store = useMemo(() => configureStore({ owners: null, insightsPropertyId }), []);

  return (
    <Provider store={store}>
      <OwnerList insightsPropertyId={insightsPropertyId} trialUser={trialUser} />
    </Provider>
  );
}

Owners.propTypes = {
  insightsPropertyId: PropTypes.number.isRequired,
  trialUser: PropTypes.bool.isRequired
};
