import React from 'react';
import { Card, Col, Row } from 'react-bootstrap-5';
import PropTypes from 'prop-types';

import { offMarketPropertyUrl } from 'bundles/connect/helpers/connect_api';
import LikelyToSellBar from './results/likely_to_sell_bar';
import OffMarketPropertyImage from './results/off_market_property_image';
import OffMarketCompSummary from './results/off_market_comp_summary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

export default function OffMarketCompDetailsCard({ property, onRemoval }) {
  return (
    <Card className="h-100">
      <Row className="g-0 align-items-stretch h-100">
        <Col className="d-flex flex-column">
          <Card.Body className="p-2">
            <div
              className={
                `d-flex
                bg-light
                rounded-circle
                clickable
                position-absolute
                justify-content-center
                align-items-center
                z-3`
              }
              style={{ width: 24, height: 24, top: 5, right: 5 }}
              onClick={() => onRemoval(property.propertyId)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <OffMarketCompSummary property={property} />
          </Card.Body>
          {!property?.listings?.forSale && (
            <LikelyToSellBar likelyToSell={property?.scores?.likelyToSell} />
          )}
        </Col>
        <Col className="map-result-thumb off-market-result-thumb d-none d-md-block" md="auto">
          <OffMarketPropertyImage result={property} />
        </Col>
      </Row>
      <a
        className="stretched-link"
        data-turbo-frame="match_details"
        href={offMarketPropertyUrl(property.propertyId)}
      />
    </Card>
  );
}

OffMarketCompDetailsCard.propTypes = {
  property: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    listings: PropTypes.shape({
      forSale: PropTypes.bool,
      salePrice: PropTypes.number,
    }),
    lastSaleDate: PropTypes.string,
    lastSalePrice: PropTypes.number,
    propertyId: PropTypes.number,
  }).isRequired,
  onRemoval: PropTypes.func.isRequired
};
