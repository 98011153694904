import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SORTING_OPTIONS } from './constants';
import { setFilterAttribute } from '../../store/actions/criteria';

const dataPropTypes = {
  data: PropTypes.shape({
    icon: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.string
  })
};

const Control = props => <components.Control className="form-control bg-white" {...props} />;

const CustomValue = ({ data }) => (
  <div className="d-flex align-items-center justify-content-between w-100">
    {data.label}
    {data.icon && <FontAwesomeIcon icon={data.icon} />}
  </div>
);

CustomValue.propTypes = dataPropTypes;

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <CustomValue data={data} />
  </components.SingleValue>
);

SingleValue.propTypes = dataPropTypes;

const Option = ({ data, ...props }) => (
  <components.Option {...props} >
    <CustomValue data={data} />
  </components.Option>
);

Option.propTypes = dataPropTypes;

const SortingSelect = ({ criteria, setFilterAttribute }) => {
  return (
    <Select
      classNamePrefix="react-select-connect"
      classNames={{
        control: ({ selectProps: { value } }) => !value.value ? '' : 'active'
      }}
      components={{
        Control,
        IndicatorsContainer: () => null,
        Option,
        SingleValue
      }}
      isSearchable
      name="sortBySelect"
      options={SORTING_OPTIONS}
      value={SORTING_OPTIONS.find(option => option.value === criteria.sortBy) || SORTING_OPTIONS[0]}
      onChange={data => setFilterAttribute('sortBy', data.value)}
    />
  );
};

SortingSelect.propTypes = {
  criteria: PropTypes.object.isRequired,
  setFilterAttribute: PropTypes.func.isRequired  
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(SortingSelect);
