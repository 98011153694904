import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { Card, Col, Row } from 'react-bootstrap-5';

import { findOffMarketProperty } from 'bundles/connect/helpers/connect_api';
import OverlayCard from './overlay_card';
import OffMarketSummary from '../results/off_market_summary';
import OffMarketPropertyImage from '../results/off_market_property_image';

function OffMarketOverlayCard({ offMarketResults, offMarketHoverPropertyId }) {
  const [property, setProperty] = useState();

  const fetchProperty = useCallback(
    debounce((id) => {
      const propertyFromMemory = (offMarketResults || []).find(property => property.propertyId === id);

      if (propertyFromMemory) {
        setProperty(propertyFromMemory);
      } else {
        findOffMarketProperty(id).then(setProperty);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (offMarketHoverPropertyId && offMarketHoverPropertyId !== property?.propertyId) {
      fetchProperty(offMarketHoverPropertyId);
    }
  }, [offMarketHoverPropertyId]);

  if (!offMarketHoverPropertyId || !property || offMarketHoverPropertyId !== property.propertyId) return null;

  return (
    <OverlayCard heightOffset={30} lat={property.latitude} lng={property.longitude}>
      <Row className="g-0 align-items-stretch map-info-window">
        <Col>
          <Card.Body className="p-2 h-100">
            <OffMarketSummary property={property} />
          </Card.Body>
        </Col>
        <Col className="map-result-thumb off-market-result-thumb overlay-card d-none d-md-block" md="auto">
          <OffMarketPropertyImage result={property} />
        </Col>
      </Row>
    </OverlayCard>
  );
}

OffMarketOverlayCard.propTypes = {
  offMarketHoverPropertyId: PropTypes.number,
  offMarketResults: PropTypes.arrayOf(PropTypes.shape({
    propertyId: PropTypes.number.isRequired
  }))
};

const mapStateToProps = ({ results: { offMarketResults }, ui: { offMarketHoverPropertyId } }) => {
  return { offMarketHoverPropertyId, offMarketResults };
};

export default connect(mapStateToProps)(OffMarketOverlayCard);
