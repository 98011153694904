import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Card, Col, Dropdown, Form, InputGroup, ListGroup, Nav, Row, Spinner } from 'react-bootstrap-5';
import { deleteContact, deleteContacts, searchContacts } from '../../helpers/connect_api';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownAZ, faArrowUpAZ, faTimes,
  faCircleExclamation, faMagnifyingGlass,
  faTrash, faTag, faUserPlus
} from '@fortawesome/pro-solid-svg-icons';
import ContactDetails from './contact_details';
import TagPicker from '../tags/tag_picker';
import Paginator from '../paginator';
import EmptyState from '../empty_state';
import { useMediaQuery } from 'react-responsive';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import CreateModal from './create_modal';
import AddToList from '../properties/add_to_list';
import { CONTACT_MODEL } from '../../constants';
import pluralize from 'pluralize';
import ConnectTagManager from '../tags/connect_tag_manager';
import { createPortal } from 'react-dom';
import ImportHandler from './import_handler';

const TAB_CONTACTS = 'contacts';
const TAB_COMPANIES = 'companies';

export default function ContactsIndex({ initialContactId }) {
  const [contacts, setContacts] = useState();
  const [contactsTotal, setContactsTotal] = useState();
  const [companies, setCompanies] = useState();
  const [companiesTotal, setCompaniesTotal] = useState();
  const [selectedContactId, setSelectedContactId] = useState(initialContactId);
  const [checkedContactIds, setCheckedContactIds] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [tab, setTab] = useState(TAB_CONTACTS);
  const [sortOrder, setSortOrder] = useState('asc');
  const [tagFilters, setTagFilters] = useState([]);
  const [page, setPage] = useState(1);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const createButtonElement = useMemo(() => document.getElementById('js-new-button-container'), []);

  const useMobileView = useMediaQuery({
    query: '(max-width: 992px)'
  });

  const search = useCallback((input, page, order, tags) => {
    return searchContacts(input, page, { name: order }, tags, true).then((res) => {
      setContacts(res.contacts.hits);
      setContactsTotal(res.contacts.total);
      setCompanies(res.companies.hits);
      setCompaniesTotal(res.companies.total);
    });
  }, []);

  const debounceSearch = useCallback(debounce(search, 300), []);
  const isAllSelected = contacts && contacts.length === checkedContactIds.length;

  useEffect(() => {
    setPage(1);
    setCheckedContactIds([]);
  }, [tab, tagFilters, searchInput, sortOrder]);

  useEffect(() => {
    if (searchInput && searchInput.length < 3) return;

    debounceSearch(searchInput ? searchInput : '*', page, sortOrder, tagFilters);
  }, [searchInput, sortOrder, tagFilters, page]);

  useEffect(() => {
    if (!useMobileView && !selectedContactId && contacts && contacts.length > 0) setSelectedContactId(contacts[0].id);
  }, [contacts]);

  const handleInputChange = useCallback((e) => {
    const { value } = e.target;
    setSearchInput(value);
  }, []);

  const handleContactDelete = useCallback((contactId, contactType) => {
    deleteContact(contactId).then(() => {
      // Reset selected contact since this only happens from a selected contact
      setSelectedContactId();
      if (contactType === 'Connect::Company') {
        setCompanies(prevCompanies => prevCompanies.filter(company => company.id !== contactId));
      } else {
        setContacts(prevContacts => prevContacts.filter(contact => contact.id !== contactId));
      }
    });
  }, []);

  const handleContactUpdate = useCallback((contact) => {
    setContacts(prevContacts => prevContacts.map(prev => prev.id === contact.id ? contact : prev));
    setCompanies(prevCompanies=> prevCompanies.map(prev => prev.id === contact.id ? contact : prev));
  }, []);

  const handlePushContact = (contact) => {
    if (contact.type == 'Connect::Person') {
      setContacts([contact, ...contacts]);
      setContactsTotal(contactsTotal + 1);
    } else if (contact.type == 'Connect::Company') {
      setCompanies([contact, ...companies]);
      setCompaniesTotal(companiesTotal + 1);
    }
  };

  const handleContactCheck = (id) => {
    if (checkedContactIds.includes(id)) {
      setCheckedContactIds(prev => prev.filter(contactId => contactId !== id));
    } else {
      setCheckedContactIds(prev => [...prev, id]);
    }
  };

  const handleSelectAll = useCallback(() => {
    setCheckedContactIds(isAllSelected ? [] : contacts.map(contact => contact.id));
  }, [isAllSelected, contacts]);

  const handleDeleteContacts = useCallback(() => {
    if (confirm(`Delete ${checkedContactIds.length} ${pluralize('Contact', checkedContactIds.length)}?`)) {
      deleteContacts(checkedContactIds).then(() => {
        if (tab === TAB_CONTACTS) {
          setContacts(contacts.filter(c => !checkedContactIds.includes(c.id)));
          setContactsTotal(prev => prev - checkedContactIds.length);
        } else if (tab === TAB_COMPANIES) {
          setCompanies(companies.filter(c => !checkedContactIds.includes(c.id)));
          setCompaniesTotal(prev => prev - checkedContactIds.length);
        }
        setCheckedContactIds([]);
      });
    }
  }, [checkedContactIds, companies, contacts, tab]);

  const renderActionsRow = () => {
    return (
      <ListGroup.Item>
        <div className="d-flex gap-3 align-items-center">
          <Form.Check
            checked={isAllSelected}
            id="select_all_contacts"
            label="Select All"
            onChange={handleSelectAll}
          />
          <AddToList
            itemCount={checkedContactIds.length}
            itemIds={new Set(checkedContactIds)}
            itemType={CONTACT_MODEL}
            tooltip={false}
            onAddToList={() => setCheckedContactIds([])}
          >
            <Button disabled={checkedContactIds.length === 0} size="sm" variant="secondary" >+ Add to List</Button>
          </AddToList>
          <Button disabled={checkedContactIds.length === 0} size="sm" variant="danger" onClick={handleDeleteContacts}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      </ListGroup.Item>
    );
  };

  const renderContactList = useCallback((contacts) => {
    if (!contacts) return (
      <div className="text-center py-4">
        <Spinner animation="border" variant="primary" />
      </div>
    );

    if (contacts.length == 0) return (
      <EmptyState icon={faCircleExclamation} title="No Results Found" />
    );

    return contacts.map(contact => (
      <ListGroup.Item
        className="py-4 fw-semibold hover-toggle-parent"
        key={contact.id}
      >
        <div
          className="d-flex gap-3 align-items-center justify-content-between"
          style={{ minHeight: '40px' }}
        >
          <div className="d-flex gap-3 text-nowrap">
            <Form.Check
              checked={checkedContactIds.includes(contact.id)}
              type="checkbox"
              onChange={() => handleContactCheck(contact.id)}
            />
            <div className="clickable text-primary" onClick={() => setSelectedContactId(contact.id)}>
              {contact.name}
            </div>
          </div>
          <ConnectTagManager
            emptyState={
              <div className="hover-d-inline">
                <Button variant="text-secondary"><FontAwesomeIcon icon={faTag} />Add Tag</Button>
              </div>
            }
            initialTags={contact.tags}
            recordId={contact.id}
            recordType={CONTACT_MODEL}
          />
        </div>
      </ListGroup.Item>
    ));
  }, [checkedContactIds]);

  const currentTabTotal = (tab === TAB_CONTACTS ? contactsTotal : companiesTotal) || 0;

  return (
    <React.Fragment>
      <Row>
        <Col className={useMobileView && selectedContactId ? 'd-none' : ''} lg={5}>
          <Card>
            <Card.Body className="d-flex flex-column gap-4">
              <Row>
                <Col>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMagnifyingGlass}/>
                    </InputGroup.Text>
                    <Form.Control
                      autoFocus
                      placeholder="Search for a contact by name"
                      type="text"
                      value={searchInput}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Col>
                <Col xs="auto">
                  <Dropdown className="border rounded">
                    <Dropdown.Toggle variant="link">
                      Name
                      <FontAwesomeIcon icon={sortOrder == 'asc' ? faArrowDownAZ : faArrowUpAZ}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setSortOrder('asc')}>
                        Name
                        <FontAwesomeIcon icon={faArrowDownAZ}/>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setSortOrder('desc')}>
                        Name
                        <FontAwesomeIcon icon={faArrowUpAZ}/>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <div className="d-flex align-items-center gap-2 flex-wrap">
                {(tagFilters || []).map(tag => (
                  <div className="d-flex align-items-stretch" key={tag}>
                    <Badge
                      bg=""
                      className="bg-connect-tag text-body rounded-end-0 clickable"
                    >
                      <small className="fw-normal">{tag}</small>
                    </Badge>
                    <Badge
                      bg=""
                      className="bg-connect-tag text-body rounded-start-0 clickable"
                      onClick={() => setTagFilters(tagFilters.filter(t => t != tag))}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Badge>
                  </div>
                ))}
                <TagPicker
                  emptyStateLabel="Add Tag Filter"
                  excludeNames={tagFilters}
                  onTagSelected={name => setTagFilters([...tagFilters, name])}
                />
              </div>
            </Card.Body>
            <Nav activeKey={tab} variant="tabs" onSelect={setTab}>
              <Nav.Item>
                <Nav.Link className="border-start-0" eventKey={TAB_CONTACTS}>
                  Contacts ({contactsTotal})
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={TAB_COMPANIES}>
                  Companies ({companiesTotal})
                </Nav.Link>
              </Nav.Item>
            </Nav>
            {tab === TAB_CONTACTS && (
              <ListGroup className="border-top-0" variant="flush">
                {renderActionsRow()}
                {renderContactList(contacts)}
              </ListGroup>
            )}
            {tab === TAB_COMPANIES && (
              <ListGroup className="border-top-0" variant="flush">
                {renderActionsRow()}
                {renderContactList(companies)}
              </ListGroup>
            )}
            {currentTabTotal > 0 && (
              <Card.Footer className="d-flex justify-content-center">
                <Paginator
                  currentPage={page}
                  perPage={20}
                  total={currentTabTotal}
                  onSelect={setPage}
                />
              </Card.Footer>
            )}
          </Card>
        </Col>
        <Col className={!useMobileView || selectedContactId ? '' : 'd-none'} lg={7}>
          <Card style={{ minHeight: '100px' }}>
            <Card.Header className="d-lg-none">
              <Button variant="link" onClick={() => setSelectedContactId()}>
                <FontAwesomeIcon icon={faChevronLeft} />Back
              </Button>
            </Card.Header>
            {selectedContactId && (
              <ContactDetails
                id={selectedContactId}
                onDelete={handleContactDelete}
                onUpdate={handleContactUpdate}
              />
            )}
          </Card>
        </Col>
      </Row>
      {showCreateModal && (
        <CreateModal defaultTab={tab} onHide={() => setShowCreateModal(false)} onPushContact={handlePushContact} />
      )}
      {createPortal((
        <React.Fragment>
          <ImportHandler />
          <Button variant="secondary" onClick={() => setShowCreateModal(true)}>
            <FontAwesomeIcon icon={faUserPlus}/>
            New Contact
          </Button>
        </React.Fragment>
      ), createButtonElement)}
    </React.Fragment>
  );
}

ContactsIndex.propTypes = {
  initialContactId: PropTypes.number
};
