import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Dropdown, Form, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAnglesUp,
  faEllipsisVertical,
  faEquals,
  faPencil,
  faTrash
} from '@fortawesome/pro-solid-svg-icons';
import { CONTACT_MODEL, PROPERTY_MODEL } from '../../../constants';
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons';
import UnstyledDropdownToggle from 'components/bootstrap_extensions/bs5/unstyled_dropdown_toggle';
import { formatDateString } from '../../../helpers/date_helpers';

const RECORD_TYPE_ICON = {
  [CONTACT_MODEL]: faUser,
  [PROPERTY_MODEL]: faBuilding,
};

export default function Task({ task, showConnectionBadges, onDelete, onEdit, onUpdate }) {
  const [saving, setSaving] = useState(false);

  const handleCompleteToggle = useCallback(() => {
    setSaving(true);
    setTimeout(() => {
      onUpdate({ id: task.id, completed: !task.completedAt }).catch(() => setSaving(false));
    }, 500);
  }, []);

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    onEdit(task);
  }, [task]);

  const priorityDisplay = (
    <span>
      {task.priority === 'low' && (<FontAwesomeIcon className="text-success" icon={faAngleDown} />)}
      {task.priority === 'medium' && (<FontAwesomeIcon className="text-warning" icon={faEquals} />)}
      {task.priority === 'high' && (<FontAwesomeIcon className="text-danger" icon={faAnglesUp} />)}
    </span>
  );

  const renderConnectedRecord = useCallback((connectedRecord) => {
    let label = connectedRecord.label;
    const fullLabel = connectedRecord.label;

    if (connectedRecord.connectedRecordType === PROPERTY_MODEL) {
      label = label.split(',')[0];
    }

    let content = (
      <Badge bg="light" key={connectedRecord.id} pill text="dark">
        <FontAwesomeIcon icon={RECORD_TYPE_ICON[connectedRecord.connectedRecordType]} />
        <small>{label}</small>
      </Badge>
    );

    if (connectedRecord.link) {
      content = <a href={connectedRecord.link} rel="noopener noreferrer" target="_blank">{content}</a>;
    }

    if (label !== fullLabel) {
      content = (
        <OverlayTrigger overlay={<Tooltip id={connectedRecord.id}>{fullLabel}</Tooltip>}>
          {content}
        </OverlayTrigger>
      );
    }

    return content;
  });

  return (
    <ListGroup.Item>
      <div className="task-item">
        <div>
          <Form.Check
            defaultChecked={!!task.completedAt}
            disabled={saving}
            type="checkbox"
            onChange={handleCompleteToggle}
          />
        </div>
        <div>
          <div className="d-flex align-items-center gap-1">
            <a href="#" onClick={handleEdit}><strong>{task.name}</strong></a>
            {showConnectionBadges !== false && (
              <div className="align-self-center d-flex gap-2 flex-wrap">
                {task.connectedRecordsAttributes.map(connectedRecord => (
                  <React.Fragment key={connectedRecord.id}>
                    {renderConnectedRecord(connectedRecord)}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
        <div>
          <Dropdown>
            <Dropdown.Toggle as={UnstyledDropdownToggle} className="text-muted">
              <FontAwesomeIcon className="clickable ps-4 pe-2 py-1" icon={faEllipsisVertical}/>
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-0">
              <Dropdown.Item className="border-top-0" onClick={handleEdit}>
                <FontAwesomeIcon className="me-2" fixedWidth icon={faPencil}/>
                Edit
              </Dropdown.Item>
              <Dropdown.Item className="border-bottom-0" onClick={() => onDelete(task.id)}>
                <FontAwesomeIcon className="me-2" fixedWidth icon={faTrash}/>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>{priorityDisplay}</div>
        <div>
          <div className="d-flex gap-3">
            {task.dueDate && <small>Due: {formatDateString(task.dueDate)}</small>}
            {task.completedAt && (
              <small className="text-success">Completed: {formatDateString(task.completedAt)}</small>
            )}
          </div>
        </div>
        <div></div>
      </div>
    </ListGroup.Item>
  );
}

Task.propTypes = {
  showConnectionBadges: PropTypes.bool,
  task: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};
