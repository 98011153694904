import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PropertyTypeFilters from 'components/research_property/property_filters/property_type_filters';

import { toggleAssetClassId } from '../../../store/actions/criteria';
import { ASSET_CLASSES } from '../constants';

function PropertyTypeInput({
  criteria,
  toggleAssetClassId,
  offMarketSearch
}) {

  const handleAssetClassChange = (e) => {
    toggleAssetClassId(parseInt(e.target.value), e.target.checked);
  };

  let assetClasses = ASSET_CLASSES;
  if (!offMarketSearch) assetClasses = assetClasses.filter(assetClass => assetClass.id !== 10);

  return (
    <div className="bs3-support">
      <PropertyTypeFilters
        assetClasses={assetClasses}
        embedded={true}
        selectedAssetClasses={criteria.assetClassIds || []}
        onAssetClassChange={handleAssetClassChange}
      />
    </div>
  );
}

PropertyTypeInput.propTypes = {
  criteria: PropTypes.shape({
    assetClassIds: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  offMarketSearch: PropTypes.bool.isRequired,
  toggleAssetClassId: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria, ui }) => ({ criteria, offMarketSearch: ui.offMarketSearch });

export default connect(mapStateToProps, { toggleAssetClassId })(PropertyTypeInput);
