import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';

import Controls from './controls';

export default function Yourself({
  certifications,
  investorTypeOptions,
  profile,
  selfReportedSourceOptions,
  onBack,
  onNext,
  onSave,
  onValueChange
}) {
  const [saving, setSaving] = useState(false);

  const professionalCertifications = profile.professionalCertifications || [];
  const investorTypes = profile.investorTypes || [];

  const submitAction = (e) => {
    e.preventDefault();
    if (onSave) {
      setSaving(true);
      onSave().then(() => setSaving(false));
    } else {
      onNext();
    }
  };

  const isDisabled = () => {
    if (profile.userType === 'broker')  return !profile.jobTitle || !profile.selfReportedSource;

    return saving || !profile.jobTitle ||
      !profile.yearsOfExperience ||
      (investorTypes.length === 0) ||
      !profile.selfReportedSource;
  };

  return (
    <form onSubmit={submitAction}>
      <div className="mb-2">
        <FontAwesomeIcon icon={faUserTie} size="2x" />
      </div>
      <h5 className="mb-4">Tell us about yourself</h5>

      <div className="mb-3">
        <Form.Label>How did you hear about us? *</Form.Label>
        <Form.Select
          value={profile.selfReportedSource || ''}
          onChange={e => onValueChange('selfReportedSource', e.target.value)}
        >
          <option value="">Make a selection</option>
          {Object.entries(selfReportedSourceOptions).map(([k, v]) => (
            <option key={k} value={k}>{v}</option>
          ))}
        </Form.Select>

        {profile.selfReportedSource === 'other' && (
          <Form.Control
            autoFocus
            className="mt-2"
            placeholder="Other source"
            value={profile.selfReportedSourceOther || ''}
            onChange={e => onValueChange('selfReportedSourceOther', e.target.value)}
          />
        )}
      </div>

      <div className="mb-3">
        <Form.Label>Job Title *</Form.Label>
        <Form.Control
          autoFocus
          placeholder="Job Title"
          value={profile.jobTitle || ''}
          onChange={e => onValueChange('jobTitle', e.target.value)}
        />
      </div>

      {profile.userType !== 'broker' && (
        <React.Fragment>
          <div className="mb-3">
            <Form.Label>How many years of experience do you have? *</Form.Label>
            <Form.Select
              value={profile.yearsOfExperience || ''}
              onChange={e => onValueChange('yearsOfExperience', e.target.value)}
            >
              <option value="">Make a selection</option>
              <option value="0">Just Starting</option>
              <option value="1-5">1 to 5 years</option>
              <option value="6-10">6 to 10 years</option>
              <option value="11-15">11 to 15 years</option>
              <option value="16-20">16 to 20 years</option>
              <option value="21+">21+ years</option>
            </Form.Select>
          </div>
          <div className="mb-3">
            <Form.Label>Are you an accredited investor?</Form.Label>
            <Form.Check
              checked={!!profile.accreditedInvestor}
              className="form-switch-lg"
              id="accredited-investor"
              label={profile.accreditedInvestor ? 'Yes' : 'No'}
              type="switch"
              onChange={e => onValueChange('accreditedInvestor', e.target.checked)}
            />
          </div>
          <Row>
            <Col lg={6} xs={12}>
              <div className="mb-3">
                <Form.Label>What type of investor are you? *</Form.Label>
                {Object.keys(investorTypeOptions).map(investorTypeKey => (
                  <div key={investorTypeKey}>
                    <Form.Check
                      checked={investorTypes.includes(investorTypeKey)}
                      id={investorTypeKey}
                      label={investorTypeOptions[investorTypeKey]}
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          onValueChange(
                            'investorTypes',
                            [...investorTypes, investorTypeKey]
                          );
                        } else {
                          onValueChange(
                            'investorTypes',
                            investorTypes.filter(t => t !== investorTypeKey)
                          );
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div className="mb-3">
                <Form.Label>What are your Professional Designations & Associations? *</Form.Label>
                <Form.Check
                  checked={professionalCertifications.length === 0}
                  id="certifications-none"
                  label="None"
                  type="checkbox"
                  onChange={() => onValueChange('professionalCertifications', [])}
                />
                {certifications.map(certification => (
                  <div key={certification}>
                    <Form.Check
                      checked={(professionalCertifications || []).includes(certification)}
                      id={certification}
                      label={certification}
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          onValueChange(
                            'professionalCertifications',
                            [...professionalCertifications, certification]
                          );
                        } else {
                          onValueChange(
                            'professionalCertifications',
                            professionalCertifications.filter(c => c !== certification)
                          );
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </React.Fragment>
      )}

      <Controls
        disabled={isDisabled()}
        showNext={!!onNext}
        showSave={!!onSave}
        onBack={onBack}
      />
    </form>
  );
}

Yourself.propTypes = {
  certifications: PropTypes.array,
  investorTypeOptions: PropTypes.object,
  profile: PropTypes.shape({
    companyName: PropTypes.string
  }).isRequired,
  selfReportedSourceOptions: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  onSave: PropTypes.func,
  onValueChange: PropTypes.func.isRequired
};
