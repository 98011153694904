import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap-5';

import TemplateForm from './template_form';
import { updateDataExportTemplate } from '../../helpers/connect_api';

export default function EditTemplateModal({ columnOptions, template, onHide, onTemplateUpdated }) {
  const [formData, setFormData] = useState({ name: '' });
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (template) {
      setFormData({ name: template.name, dataExportType: template.dataExportType });
      if (template.dataExportType == 'activity') {
        const columns = template.columns.map(c => columnOptions.activity.find(col => col.value == c));
        setSelectedColumns(columns);
      } else if (template.dataExportType == 'property') {
        const columns = template.columns.map(c => columnOptions.property.find(col => col.value == c));
        setSelectedColumns(columns);
      }
    } else {
      setFormData({ name: '' });
      setSelectedColumns([]);
    }
  }, [template]);

  const handleSave = () => {
    setLoading(true);
    const params = {
      ...formData,
      columns: selectedColumns.map(c => c.value)
    };

    updateDataExportTemplate(template.id, params).then((response) => {
      setLoading(false);
      onHide();
      onTemplateUpdated(response.dataExportTemplate);
    });
  };

  const canSubmit = useMemo(() => {
    if (loading) return false;

    return formData.name.length > 2 && selectedColumns.length > 0;
  }, [loading, formData.name, selectedColumns]);

  return (
    <Modal show={!!template} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Data Export Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TemplateForm
          columnOptions={columnOptions}
          disableTypeSelector
          formData={formData}
          selectedColumns={selectedColumns}
          setFormData={setFormData}
          setSelectedColumns={setSelectedColumns}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} variant="outline-primary" onClick={onHide}>Cancel</Button>
        <Button disabled={!canSubmit} variant="secondary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

EditTemplateModal.propTypes = {
  columnOptions: PropTypes.shape({
    property: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
    activity: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
  }).isRequired,
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    dataExportType: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string)
  }),
  onHide: PropTypes.func.isRequired,
  onTemplateUpdated: PropTypes.func.isRequired
};
