import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap-5';
import flatfileBlueprint from './flatfile_blueprint';
import { importContacts } from '../../helpers/connect_api';
import { Modal } from 'react-bootstrap-5';
import EmptyState from '../empty_state';
import { faCloudArrowUp, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ImportHandler() {
  if (!window.FLATFILE_PUBLIC_KEY) return null;

  const [showDone, setShowDone] = useState(false);

  useEffect(() => {
    if (!window.FlatFileJavaScript) {
      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://unpkg.com/@flatfile/javascript@1.4.4/dist/index.js';
      document.head.appendChild(scriptElement);
    }
  }, []);

  const handleClick = () => {
    window.FlatFileJavaScript.startFlatfile({
      publishableKey: window.FLATFILE_PUBLIC_KEY,
      namespace: 'portal',
      sheet: flatfileBlueprint,
      closeSpace: {
        operation: 'simpleSubmitAction',
        onClose: () => {},
      },
      onSubmit: async ({ sheet }) => {
        importContacts(sheet.sheetId);
        setShowDone(true);
      },
      onRecordHook: (record) => {
        const email = record.get('email');
        const validEmailAddress = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!!email && !validEmailAddress.test(email)) {
          record.addError('email', 'Invalid email address');
        }

        const secondaryEmail = record.get('secondaryEmail');

        if (!!secondaryEmail && !validEmailAddress.test(secondaryEmail)) {
          record.addError('secondaryEmail', 'Invalid email address');
        }

        const companyName = record.get('companyName');

        const companyFields = [
          record.get('companyAddress'),
          record.get('companyPhoneNumber'),
          record.get('companyWebsite'),
          record.get('companyFax'),
          record.get('companyDescription'),
          record.get('companyTags'),
          record.get('companyNotes'),
          record.get('companyExternalId')
        ];
        if (companyFields.some(field => !!field) && !companyName) {
          record.addError(
            'companyName',
            'Company name must be present if other company fields are present.'
          );
        }
        return record;
      },
      themeConfig: {
        root: {
          primaryColor: '#7422CE',
          textColor: 'white'
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Button onClick={handleClick}>
        <FontAwesomeIcon icon={faFileUpload} />
        Import Contacts
      </Button>
      <Modal show={showDone} onHide={() => setShowDone(false)}>
        <Modal.Body>
          <EmptyState
            icon={faCloudArrowUp}
            iconSize="2x"
            text="Your import has been sent to our servers. Your contacts will show up once they are imported."
            title="Imported Started"
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
