import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Spinner } from 'react-bootstrap-5';
import { createActivity } from '../../../helpers/connect_api';
import { ACTIVITY_TYPE_CALL, TASK_PRIORITY_MEDIUM } from '../../../constants';
import TaskForm from './task_form';

const DEFAULT_NEW_ACTIVITY = {
  name: '',
  priority: TASK_PRIORITY_MEDIUM,
  description: '',
  type: ACTIVITY_TYPE_CALL,
  connectedRecordsAttributes: [],
  isTask: true
};

export default function NewTaskModal({
  autoAddConnectedRecords,
  callListId,
  initialConnectedRecords,
  show,
  showContactInput = true,
  title = 'New Task',
  onAdd,
  onHide
}) {
  const [activity, setActivity] = useState(DEFAULT_NEW_ACTIVITY);
  const [saving, setSaving] = useState(false);
  const isValid = activity.name !== '';

  useEffect(() => {
    if (!show) setActivity(DEFAULT_NEW_ACTIVITY);
  }, [show]);

  useEffect(() => {
    setActivity({ ...DEFAULT_NEW_ACTIVITY, ...{ connectedRecordsAttributes: initialConnectedRecords || [] } });
  }, [initialConnectedRecords]);

  const handleSave = () => {
    setSaving(true);

    const activityParams = { ...activity };
    activityParams.connectedRecordsAttributes = [
      ...activity.connectedRecordsAttributes,
      ...(autoAddConnectedRecords || [])
    ];

    createActivity(activityParams, callListId)
      .then((activity) => {
        onAdd(activity);
        setActivity(DEFAULT_NEW_ACTIVITY);
      })
      .catch(() => {
        // errors
      })
      .finally(() => {
        setSaving(false);
        onHide();
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        <TaskForm activity={activity} showContactInput={showContactInput} onChange={setActivity} />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={saving || !isValid} variant="secondary" onClick={handleSave}>
          {saving && <Spinner animation="border" size="sm" />}
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const connectedRecordPropType = PropTypes.shape({
  connectedRecordId: PropTypes.number.isRequired,
  connectedRecordType: PropTypes.string.isRequired,
  label: PropTypes.string
});

NewTaskModal.propTypes = {
  autoAddConnectedRecords: PropTypes.arrayOf(connectedRecordPropType),
  callListId: PropTypes.number,
  initialConnectedRecords: PropTypes.arrayOf(connectedRecordPropType),
  show: PropTypes.bool.isRequired,
  showContactInput: PropTypes.bool,
  title: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
};
