import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Button, Col, OverlayTrigger, Popover, Row, Tooltip
} from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleHistoryCirclePlus, faUserCheck, faUserTag } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import { claimProperty, removeClaimedProperty } from '../../helpers/connect_api';
import AddToList from '../properties/add_to_list';
import PropertyBookmark from '../search/components/results/property_bookmark';
import { PROPERTY_MODEL } from '../../constants';

export default function PropertyActions({
  address,
  bookmarked,
  initialConnectPropertyId,
  insightsPropertyId,
  claimed,
  products
}) {
  const target = useRef(null);

  const [connectPropertyId, setConnectPropertyId] = useState(initialConnectPropertyId);
  const [claimedProperty, setClaimedProperty] = useState(claimed);

  const handleClaimProperty = useCallback(() => {
    claimProperty(insightsPropertyId).then(({ id }) => {
      setClaimedProperty(true);
      setConnectPropertyId(id);
    });
  }, []);

  const handleRemoveClaimedProperty = useCallback(() => {
    removeClaimedProperty(connectPropertyId).then(() => setClaimedProperty(false));
  }, [connectPropertyId]);

  const popover = useMemo(() => {
    return (
      <Popover id="popover-basic">
        <Popover.Header as="h3">Claim this property</Popover.Header>
        <Popover.Body>
          <p>
            I agree as follows:<br />
            (i) I am (or I have the authority to act on behalf of) the owner of this property;<br />
            (ii) I will not provide incorrect information or state a discriminatory preference;<br />
            and (iii) I will comply with the Buildout Terms of Use.
          </p>
          <Button className="secondary" onClick={handleClaimProperty}>
            Submit
          </Button>
        </Popover.Body>
      </Popover>
    );
  });

  return (
    <Row className="gx-2">
      {products.prospecting && (
        <Col xs="auto">
          <PropertyBookmark bookmarked={bookmarked} id={insightsPropertyId}/>
        </Col>
      )}
      <Col xs="auto">
        {claimedProperty && (
          <OverlayTrigger overlay={<Tooltip>Unclaim Property</Tooltip>} placement="auto">
            <Button variant="text-primary" onClick={handleRemoveClaimedProperty}>
              <FontAwesomeIcon icon={faUserCheck}/>
            </Button>
          </OverlayTrigger>
        )}
        {!claimedProperty && (
          <OverlayTrigger overlay={<Tooltip>Claim Property</Tooltip>} placement="auto">
            <div>
              <OverlayTrigger overlay={popover} placement="bottom" trigger="click">
                <Button variant="text-primary"><FontAwesomeIcon icon={faUserTag} /></Button>
              </OverlayTrigger>
            </div>
          </OverlayTrigger>
        )}
      </Col>
      {products.prospecting && (
        <Col xs="auto">
          {products.prospecting && (
            <AddToList
              itemCount={1}
              itemIds={new Set([insightsPropertyId])}
              itemType={PROPERTY_MODEL}
              tooltip={false}
            >
              <Button variant="secondary">
                <FontAwesomeIcon className="me-1" icon={faRectangleHistoryCirclePlus}/> Add To List
              </Button>
            </AddToList>
          )}
        </Col>
      )}
    </Row>
  );
}

PropertyActions.propTypes = {
  address: PropTypes.string.isRequired,
  bookmarked: PropTypes.bool.isRequired,
  claimed: PropTypes.bool.isRequired,
  initialConnectPropertyId: PropTypes.number,
  insightsPropertyId: PropTypes.number.isRequired,
  products: PropTypes.shape({
    prospecting: PropTypes.bool.isRequired
  }).isRequired
};
