import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap-5';

import TemplateForm from './template_form';
import { createDataExportTemplate } from '../../helpers/connect_api';

export default function NewTemplateModal({ columnOptions, show, onTemplateAdded, onHide }) {
  const [formData, setFormData] = useState({ name: '' });
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      setFormData({ name: '' });
      setSelectedColumns([]);
    }
  }, [show]);

  useEffect(() => {
    setSelectedColumns([]);
  }, [formData.dataExportType]);

  const handleSave = () => {
    setLoading(true);
    const params = {
      ...formData,
      columns: selectedColumns.map(c => c.value)
    };

    createDataExportTemplate(params).then((response) => {
      setLoading(false);
      onHide();
      onTemplateAdded(response.dataExportTemplate);
    });
  };

  const canSubmit = useMemo(() => {
    if (loading) return false;

    return formData.name.length > 2 && selectedColumns.length > 0;
  }, [loading, formData.name, selectedColumns]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>New Data Export Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TemplateForm
          columnOptions={columnOptions}
          formData={formData}
          selectedColumns={selectedColumns}
          setFormData={setFormData}
          setSelectedColumns={setSelectedColumns}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} variant="outline-primary" onClick={onHide}>Cancel</Button>
        <Button disabled={!canSubmit} variant="secondary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

NewTemplateModal.propTypes = {
  columnOptions: PropTypes.shape({
    property: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
    activity: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onTemplateAdded: PropTypes.func.isRequired
};
