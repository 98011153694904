import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import FilterCheckboxGroup from 'components/search_sidebar/filter_checkbox_group';

export default class PropertyTypeFilters extends React.Component {
  static propTypes = {
    assetClasses: PropTypes.array.isRequired,
    embedded: PropTypes.bool,
    selectedAssetClasses: PropTypes.array.isRequired,
    onAssetClassChange: PropTypes.func.isRequired
  };

  handleAssetClassChange = (e, assetClass) => {
    const { selectedAssetClasses, onAssetClassChange } = this.props;
    const selectedSubclasses = assetClass.subclasses.filter(sc => selectedAssetClasses.includes(sc.value));
    const { checked } = e.target;
    if (selectedSubclasses.length > 0) {
      if (!checked) {
        selectedSubclasses.forEach((subclass) => {
          onAssetClassChange({ target: { value: subclass.value, checked: false } });
        });
      }
    }
    onAssetClassChange(e);
  }

  renderCheckboxGroup = (assetClass) => {
    const subclassOptionIds = assetClass.subclasses.map(c => c.value);
    const selectedSubclasses = this.props.selectedAssetClasses.filter(c => subclassOptionIds.includes(c));

    return (
      <div key={assetClass.value}>
        <div className="my-1">
          <FilterCheckboxGroup
            options={[assetClass]}
            selected={this.props.selectedAssetClasses}
            onChange={e => this.handleAssetClassChange(e, assetClass)}
          />
          {assetClass.hint && <small className="text-muted">{assetClass.hint}</small>}
        </div>
        {this.props.selectedAssetClasses.includes(assetClass.value) &&
          <div className="ml-5">
            <FilterCheckboxGroup
              checkAllIfNone={true}
              options={assetClass.subclasses}
              selected={selectedSubclasses}
              onChange={this.props.onAssetClassChange}
            />
          </div>
        }
      </div>
    );
  }

  render() {
    const { assetClasses, embedded } = this.props;
    return (
      <div className={classNames({ 'hidden-xs': !embedded })}>
        {!embedded &&
          <React.Fragment>
            <h4 className="bold mb-0">Property Type</h4>
            <hr className="mt-2 mb-4"/>
          </React.Fragment>
        }
        <div>
          {assetClasses.map(this.renderCheckboxGroup)}
        </div>
      </div>
    );
  }
}
