import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap-5';
import classNames from 'classnames';

export const Filter = ({ bordered, children, title }) => {
  return (
    <div>
      {title && <Form.Label>{title}</Form.Label>}
      <div className={classNames({ 'p-2 border rounded': bordered })}>
        {children}
      </div>
    </div>
  );
};

Filter.propTypes = {
  bordered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export const ColumnHeader = ({ children }) => (
  <div className="mb-3 bg-light px-3 py-2 border-bottom border-top">
    <h6 className="m-0">{children}</h6>
  </div>
);
ColumnHeader.propTypes = { children: PropTypes.node.isRequired };
