import { deleteActivity, updateActivity as updateActivityAPI } from 'bundles/connect/helpers/connect_api';
import { displayToast } from '../../../../helpers/display_toast';

export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const REMOVE_ACTIVITY = 'REMOVE_ACTIVITY';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';

function resubmitFormIndex() {
  // Hacky, but quick. When this store is hosted on activities index page, refresh the frame when
  // a change is made to an activity
  setTimeout(() => {
    const taskIndexForm = document.getElementById('connect/activity_search');
    if (taskIndexForm) taskIndexForm.requestSubmit();
  }, 300);
}

export function addActivity(activity) {
  resubmitFormIndex();
  return { type: ADD_ACTIVITY, payload: activity };
}

export function removeActivity(id, type = 'Activity') {
  if (confirm('Are you sure you want to delete this?')) {
    return (dispatch) => {
      return deleteActivity(id).then(() => {
        displayToast(`${type} deleted`, 'success');
        resubmitFormIndex();
        dispatch({ type: REMOVE_ACTIVITY, payload: id });
      });
    };
  }
}

export function setActivities(activities) {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVITIES, payload: activities });
  };
}

export function updateActivity(activity) {
  resubmitFormIndex();
  return (dispatch) => {
    return updateActivityAPI(activity).then((returnedActivity) => {
      displayToast(`${returnedActivity.isTask ? 'Task' : 'Activity'} updated`, 'success');
      dispatch({ type: UPDATE_ACTIVITY, payload: returnedActivity });
    });
  };
}
