import { setLoading } from './ui';
import {
  bothOffMarketPropertySearchesFromCriteria,
  detailedListingSearch,
  detailedOffMarketPropertySearch,
  listingSearch,
  saveSearch as saveSearchRequest
} from 'bundles/connect/helpers/connect_api';
import { trackEvent, trackFilters } from '../../helpers/google_analytics_helpers';

import debounceActionCreators from 'helpers/redux/debounce_action_creators';
const debouncedActionCreators = debounceActionCreators({ search }, 500);

export const debouncedSearch = debouncedActionCreators.search;
export const SET_RESULTS = 'SET_RESULTS';
const OFF_MARKET_DETAILED_RESULTS_LIMIT = 20;
const OFF_MARKET_MAP_RESULTS_LIMIT = 500;
let abortController;

export function paginate(page = 1) {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { criteria, ui } = getState();
    if (ui.offMarketSearch) {
      const offset = OFF_MARKET_DETAILED_RESULTS_LIMIT * (page - 1);

      detailedOffMarketPropertySearch(criteria, OFF_MARKET_DETAILED_RESULTS_LIMIT, offset)
        .then((detailedResults) => {
          dispatch(setLoading(false));
          dispatch({
            type: SET_RESULTS,
            payload: {
              offMarketResults: detailedResults.hits || [],
              page: detailedResults.page,
              total: detailedResults.total,
              totalPages: detailedResults.totalPages
            }
          });
        });
    } else {
      detailedListingSearch(criteria, page).then((detailedResults) => {
        dispatch(setLoading(false));
        dispatch({
          type: SET_RESULTS,
          payload: {
            detailedResults: detailedResults.results,
            page: detailedResults.page,
            total: detailedResults.total,
            totalPages: detailedResults.totalPages
          }
        });
      });
    }
  };
}

export function search() {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { criteria, ui } = getState();

    if (abortController) abortController.abort();
    abortController = new AbortController();
    const signal = abortController.signal;

    trackFilters(ui.offMarketSearch, criteria);

    if (ui.offMarketSearch) {
      trackEvent('connect_off_market_search');
      const includeGeometry = ui.mapZoomReadonly && ui.mapZoomReadonly >= 15;

      const promises = await bothOffMarketPropertySearchesFromCriteria(
        criteria,
        OFF_MARKET_DETAILED_RESULTS_LIMIT,
        OFF_MARKET_MAP_RESULTS_LIMIT,
        includeGeometry,
        signal
      );

      try {
        const [detailedResults, allResults] = await Promise.all(promises);

        dispatch(setLoading(false));
        dispatch({
          type: SET_RESULTS,
          payload: {
            allOffMarketResults: allResults.hits || [],
            totalOffMarketResults: allResults.total,
            offMarketResults: detailedResults.hits || [],
            page: 1,
            total: detailedResults.total,
            totalPages: detailedResults.totalPages
          }
        });
      } catch (error) {
        if (!signal.aborted) throw error;
      }
    } else {
      trackEvent('connect_exclusive_listing_search');
      const allResultsRequest = listingSearch(criteria, signal);
      const detailedResultsRequest = detailedListingSearch(criteria, 0, signal);

      try {
        const [allResults, detailedResults] = await Promise.all([allResultsRequest, detailedResultsRequest]);

        dispatch(setLoading(false));
        dispatch({
          type: SET_RESULTS,
          payload: {
            allResults,
            detailedResults: detailedResults.results,
            page: 1,
            total: detailedResults.total,
            totalPages: detailedResults.totalPages
          }
        });
      } catch (error) {
        if (!signal.aborted) throw error;
      }
    }
  };
}



export const SAVE_SEARCH = 'SAVE_SEARCH';
export function saveSearch(name) {
  return async (_dispatch, getState) => {
    const { criteria, ui } = { ...getState() };
    const eventName = ui.offMarketSearch ? 'connect_off_market_save_search' : 'connect_exclusive_listing_save_search';
    trackEvent(eventName);
    return saveSearchRequest(
      criteria,
      name,
      ui.offMarketSearch ? 'Connect::OffMarketSavedSearch' : 'Connect::BuildoutSavedSearch'
    );
  };
}
