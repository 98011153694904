import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConnectTagManager from '../tags/connect_tag_manager';
import { Accordion, Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFiles, faNote, faTag } from '@fortawesome/pro-solid-svg-icons';
import PropertyNoteForm from './property_note_form';
import { isEmpty } from 'lodash';
import NoteSection from './note_section';

export default function PropertyOverviewActions({ hideNote, insightsPropertyId, initialNotes, tags }) {
  const [notes, setNotes] = useState(initialNotes || []);
  const [addingNote, setAddingNote] = useState(false);
  const [noteAccordionKey, setNoteAccordionKey] = useState(null);

  const handleNewNote = (e) => {
    setAddingNote(true);
    setNoteAccordionKey('0');
    e.stopPropagation();
  };

  const handleAddNote = (note) => {
    setNotes([note, ...notes]);
    setAddingNote(false);
  };

  const handleUpdateNote = (noteUpdate) => {
    setNotes(notes.map((note) => {
      if (noteUpdate.id == note.id) {
        if (isEmpty(noteUpdate.body)) {
          return false;
        } else {
          return noteUpdate;
        }
      }
      return note;
    }).filter(n => n));
  };

  const handleRemoveNote = (noteId) => {
    setNotes(notes.filter(n => n.id != noteId));
  };

  const toggleAccordion = () => {
    if (noteAccordionKey == '0') {
      setNoteAccordionKey(null);
      setAddingNote(false);
    } else {
      setNoteAccordionKey('0');
    }
  };

  const showNotesAccordion = (notes.length > 0) || addingNote;

  return (
    <div
      className="d-flex flex-column gap-3"
      onClick={e => e.stopPropagation()}
    >
      <div className="d-flex align-items-center gap-2">
        <ConnectTagManager
          emptyState={(
            <Button variant="outline-primary">
              <FontAwesomeIcon icon={faTag} />
              Add Tag
            </Button>
          )}
          initialTags={tags}
          insightsPropertyId={insightsPropertyId}
        />
        {!hideNote && (
          <Button
            variant="outline-primary"
            onClick={handleNewNote}
          >
            <FontAwesomeIcon icon={faNote} />
            Add Note
          </Button>
        )}
      </div>
      {!hideNote && showNotesAccordion && (
        <Accordion activeKey={noteAccordionKey}>
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={toggleAccordion}>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="fw-bold">
                  <FontAwesomeIcon className="me-3 text-secondary" icon={faFiles} />
                  Notes {notes.length > 0 && `(${notes.length})`}
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body className="p-0">
              {addingNote && (
                <PropertyNoteForm
                  insightsPropertyId={insightsPropertyId}
                  onClose={() => setAddingNote(false)}
                  onSave={handleAddNote}
                />
              )}
              {!isEmpty(notes) && notes.map(note => (
                <NoteSection
                  key={note.id}
                  note={note}
                  onRemove={handleRemoveNote}
                  onUpdate={handleUpdateNote}
                />
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </div>
  );
}

PropertyOverviewActions.propTypes = {
  hideNote: PropTypes.bool,
  initialNotes: PropTypes.array,
  insightsPropertyId: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string)
};

