import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';

export default function OtherForm({ activity, onUpdate }) {
  return (
    <div className="d-flex flex-column gap-3">
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={activity.description}
          onChange={e => onUpdate({ ...activity, description: e.target.value })}
        />
      </Form.Group>
    </div>
  );
}

OtherForm.propTypes = {
  activity: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
};
