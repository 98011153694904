import React from 'react';
import PropTypes from 'prop-types';

function addBtnClass(element, selected) {
  if (selected) {
    element.classList.add('btn-primary');
  } else {
    element.classList.add('btn-light', 'bg-white', 'text-primary');
  }
}

export default function CustomDrawingControls({
  map,
  position,
  drawingMode,
  onClear,
  onDragClick,
  onPolygonClick,
  onRectangleClick
}) {
  React.useEffect(() => {
    if (!map) return;
    const controls = map.controls[position];
    controls.clear();

    const btnGroup = document.createElement('div');
    btnGroup.classList.add('btn-group', 'm-2');

    const dragDiv = document.createElement('div');
    dragDiv.innerHTML = '<i class="fa fas fa-hand" />';
    dragDiv.classList.add('btn', 'px-3');
    addBtnClass(dragDiv, !drawingMode);
    dragDiv.onclick = onDragClick;

    const polygonDiv = document.createElement('div');
    polygonDiv.innerHTML = '<i class="fa fas fa-draw-polygon" />';
    polygonDiv.classList.add('btn', 'px-3');
    addBtnClass(polygonDiv, drawingMode === google.maps.drawing.OverlayType.POLYGON);
    polygonDiv.onclick = onPolygonClick;

    const rectangleDiv = document.createElement('div');
    rectangleDiv.innerHTML = '<i class="fa fas fa-draw-square" />';
    rectangleDiv.classList.add('btn', 'px-3');
    addBtnClass(rectangleDiv, drawingMode === google.maps.drawing.OverlayType.RECTANGLE);
    rectangleDiv.onclick = onRectangleClick;

    const clearDiv = document.createElement('div');
    clearDiv.innerHTML = '<i class="fa fas fa-times text-danger -sm" />';
    clearDiv.classList.add('btn', 'btn-light', 'bg-white', 'px-3');
    clearDiv.onclick = onClear;

    btnGroup.append(dragDiv);
    btnGroup.append(polygonDiv);
    btnGroup.append(rectangleDiv);
    btnGroup.append(clearDiv);

    controls.push(btnGroup);
    const index = controls.length;
    return () => {
      controls.removeAt(index - 1);
    };
  }, [map, drawingMode]);

  return null;
}

CustomDrawingControls.propTypes = {
  map: PropTypes.object,
  position: PropTypes.number.isRequired,
  onDragClick: PropTypes.func.isRequired,
  onPolygonClick: PropTypes.func.isRequired
};
