import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Spinner } from 'react-bootstrap-5';
import CallForm, { CALL_RESULTS } from './call_form';
import { createActivity, evaluateTemplate } from '../../../helpers/connect_api';
import OtherForm from './other_form';
import {
  ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL, ACTIVITY_TYPE_OTHER, CONTACT_MODEL, PROPERTY_MODEL
} from '../../../constants';
import { addActivity } from '../store/actions/activities';
import { connect } from 'react-redux';
import ButtonGroupRadio from './button_group_radio';
import { faFloppyDisk, faPencil, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import EmailForm from './email_form';
import NewTaskModal from '../tasks/new_task_modal';
import { setOwnerContactMethodScore } from '../store/actions/owners';
import { displayToast } from '../../../helpers/display_toast';

const TYPE_OPTIONS = [
  { icon: faPhone, label: 'Call', value: ACTIVITY_TYPE_CALL, },
  { icon: faEnvelope, label: 'Email', value: ACTIVITY_TYPE_EMAIL },
  { icon: faPencil, label: 'Other', value: ACTIVITY_TYPE_OTHER }
];

const DEFAULT_NEW_ACTIVITY = {
  callResult: CALL_RESULTS[0].value,
  description: '',
  location: '',
  emailSubject: '',
  type: ACTIVITY_TYPE_CALL,
  connectedRecordsAttributes: []
};

function NewActivityForm(
  { addActivity, callListId, callTemplates, owner, initialActivity, propertyId, setOwnerContactMethodScore }
) {
  const [activity, setActivity] = useState(DEFAULT_NEW_ACTIVITY);
  const [saving, setSaving] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [createFollowUp, setCreateFollowUp] = useState(false);
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);

  const propertyConnectedRecord = propertyId ? {
    connectedRecordId: propertyId,
    connectedRecordType: PROPERTY_MODEL
  } : undefined;

  const contactConnectedRecord =     {
    connectedRecordId: owner.contact.id,
    connectedRecordType: CONTACT_MODEL,
    label: owner.contact.name
  };

  useEffect(() => {
    if (!selectedTemplate) {
      setActivity(prevActivity => ({ ...prevActivity, script: undefined }));
      return;
    }

    evaluateTemplate(selectedTemplate, owner.contact.id, propertyId)
      .then(script => setActivity(prevActivity => ({ ...prevActivity, script })));
  }, [selectedTemplate, activity.connectedRecordsAttributes]);

  useEffect(() => {
    setActivity({ ...DEFAULT_NEW_ACTIVITY, ...initialActivity });
  }, [initialActivity.type, initialActivity.email, initialActivity.phoneNumber]);

  const handleSave = () => {
    setSaving(true);

    const activityParams = {
      ...activity,
      connectedRecordsAttributes: [
        ...activity.connectedRecordsAttributes,
        ...(propertyId ? [{ connectedRecordId: propertyId, connectedRecordType: PROPERTY_MODEL }] : []),
        { connectedRecordId: owner.contact.id, connectedRecordType: CONTACT_MODEL }
      ]
    };

    createActivity(activityParams, callListId)
      .then((activity) => {
        displayToast('Activity created', 'success');
        addActivity(activity);
        if (activity.callResult === 'bad_phone' && owner.id && activity.phoneNumber) {
          const phoneNumber = owner.contact.phoneNumbers
            .find(phoneNumber => phoneNumber.number === activity.phoneNumber);

          if (phoneNumber) {
            setOwnerContactMethodScore(owner, 'phoneNumbers', phoneNumber.id, -1);
          }
        }
        setSelectedTemplate();
        setActivity(DEFAULT_NEW_ACTIVITY);
      }).then(() => {
        if (createFollowUp) {
          setShowNewTaskModal(true);
          setCreateFollowUp(false);
        }
      })
      .catch(() => {
        // errors
      })
      .finally(() => setSaving(false));
  };

  let form;
  if (activity.type === ACTIVITY_TYPE_CALL) {
    form = (
      <CallForm
        activity={activity}
        callTemplates={callTemplates}
        phoneNumbers={owner.contact.phoneNumbers}
        selectedTemplate={selectedTemplate}
        onTemplateSelect={setSelectedTemplate}
        onUpdate={setActivity}
      />
    );
  }
  if (activity.type === ACTIVITY_TYPE_EMAIL) {
    form = <EmailForm activity={activity} emails={owner.contact.emails} onUpdate={setActivity} />;
  }
  if (activity.type === ACTIVITY_TYPE_OTHER) form = <OtherForm activity={activity} onUpdate={setActivity} />;

  return (
    <React.Fragment>
      <div className="d-flex flex-column gap-3">
        <h6 className="m-0">Log Activity</h6>
        <Form.Group>
          <Form.Label>Activity Type</Form.Label>
          <ButtonGroupRadio
            checked={activity.type}
            name="activity_type"
            options={TYPE_OPTIONS}
            onChange={type => setActivity({ ...activity, type })}
          />
        </Form.Group>
        {form}
        <div className="d-flex align-items-center ms-auto gap-2">
          <Form.Check
            checked={createFollowUp}
            id="follow-up-task"
            label="Create follow-up task"
            type="checkbox"
            onChange={() => setCreateFollowUp(!createFollowUp)}
          />
          <Button disabled={saving} variant="secondary" onClick={handleSave}>
            {saving && <Spinner animation="border" size="sm" />}
            {!saving && <FontAwesomeIcon icon={faFloppyDisk} />}
            Save Activity
          </Button>
        </div>
      </div>
      <NewTaskModal
        autoAddConnectedRecords={propertyConnectedRecord ? [propertyConnectedRecord] : undefined}
        callListId={callListId}
        initialConnectedRecords={[contactConnectedRecord]}
        show={showNewTaskModal}
        title="Follow-up Task"
        onAdd={addActivity}
        onHide={() => setShowNewTaskModal(false)}
      />
    </React.Fragment>
  );
}

NewActivityForm.propTypes = {
  addActivity: PropTypes.func.isRequired,
  callListId: PropTypes.number.isRequired,
  callTemplates: PropTypes.array.isRequired,
  initialActivity: PropTypes.object.isRequired,
  owner: PropTypes.object.isRequired,
  propertyId: PropTypes.number,
  setOwnerContactMethodScore: PropTypes.func.isRequired
};

export default connect(undefined, { addActivity, setOwnerContactMethodScore })(NewActivityForm);
