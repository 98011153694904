import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';

import StateSelect from 'components/state_select';
import Controls from './controls';
import { countries } from 'helpers/countries';

export default function Company({ profile, onBack, onNext, onValueChange }) {
  const submit = (e) => {
    e.preventDefault();
    onNext();
  };

  return (
    <React.Fragment>
      <div className="mb-2">
        <FontAwesomeIcon icon={faBriefcase} size="2x" />
      </div>
      <h5 className="mb-4">Tell us about your company</h5>

      <form onSubmit={submit}>
        <Form.Label>Company Name *</Form.Label>
        <Row className="mb-3 align-items-center gy-4">
          <Col xs={10}>
            <Form.Control
              autoFocus
              placeholder="Company Name"
              value={profile.companyName || ''}
              onChange={e => onValueChange('companyName', e.target.value)}
            />
          </Col>
          <Col xs="auto">
            <Form.Check
              checked={profile.companyIsSelf}
              id="company-self"
              label="Self"
              type="checkbox"
              onChange={e => onValueChange('companyIsSelf', e.target.checked)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={10}>
            {profile.userType !== 'broker' && (
              <div className="mb-3">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  placeholder="Company Website"
                  value={profile.companyWebsite || ''}
                  onChange={e => onValueChange('companyWebsite', e.target.value)}
                />
              </div>
            )}
            <div className="mb-3">
              <Form.Label>Country *</Form.Label>
              <Form.Select
                value={profile.countryId}
                onChange={e => onValueChange('countryId', parseInt(e.target.value))}
              >
                {countries.map(({ id, label }) => {
                  return <option key={id} value={id}>{label}</option>;
                })}
              </Form.Select>
            </div>
            <div className="mb-3">
              <Form.Label>Company City *</Form.Label>
              <Form.Control
                placeholder="Company City"
                value={profile.city || ''}
                onChange={e => onValueChange('city', e.target.value)}
              />
            </div>
            {profile.countryId === 1 && (
              <div className="mb-3">
                <Form.Label>Company State *</Form.Label>
                <StateSelect
                  className="form-select"
                  value={profile.state}
                  onChange={e => onValueChange('state', e.target.value)}
                />
              </div>
            )}
          </Col>
        </Row>
        <Controls
          disabled={
            !(profile.companyIsSelf || profile.companyName) ||
            !(profile.countryId != 1 || profile.state) ||
            !profile.city
          }
          showNext
          onBack={onBack}
        />
      </form>
    </React.Fragment>
  );
}

Company.propTypes = {
  profile: PropTypes.shape({
    companyName: PropTypes.string
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired
};
