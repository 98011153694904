import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CA_NOT_SIGNED, COLLECT_INFORMATION, INVALID_PROFILE, REGISTRATION } from '../../helpers/vault_access_helper';
import { Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { setPrimaryRole } from '../../actions';
import { connect } from 'react-redux';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { get } from 'helpers/api';
import GateAccountFields from './gates/account_fields';
import CaContainer from './gates/ca_container';
import pick from 'lodash/pick';
import { post } from 'axios';
import snakecaseKeys from 'snakecase-keys';
import { uniq } from 'lodash';
import { getQueryParameter } from 'helpers/browser_helpers';

const baseFormFields = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  companyName: '',
  jobTitle: '',
  countryId: 1,
  address: '',
  city: '',
  state: '',
  zip: '',
  investorTypes: [],
};

export function GateForm({
  nextStep,
  noDocs,
  previousStep,
  readonly,
  setPrimaryRole,
  steps,
  primaryRole,
  vaultGatesFormPath,
  vaultGatesProfilePath,
  vaultGatesVerifyEmailPath,
  onNext
}) {
  const [loadingData, setLoadingData] = useState(true);
  const [accountFormFields, setAccountFormFields] = useState(baseFormFields);
  const [caData, setCaData] = useState({ text: '', type: '', filePath: '' });
  const [caFields, setCaFields] = useState({ checked: false });
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [emailTaken, setEmailTaken] = useState(false);

  useEffect(() => {
    get(vaultGatesProfilePath).then((data) => {
      if (Object.keys(data.profileFields).length > 0) {
        setAccountFormFields({
          ...accountFormFields,
          email: data.profileFields.email || '',
          firstName: data.profileFields.firstName || '',
          lastName: data.profileFields.lastName || '',
          phoneNumber: data.profileFields.phoneNumber || '',
          companyName: data.profileFields.companyName || '',
          jobTitle: data.profileFields.jobTitle || '',
          countryId: data.profileFields.countryId
        });
      }

      if (Object.keys(data.caFields).length > 0) {
        setCaData({ text: data.caFields.text, type: data.caFields.type, filePath: data.caFields.filePath });
      }
      setLoadingData(false);
    });

  }, []);

  const backToUserType = () => {
    setPrimaryRole(null);
    onNext(previousStep);
  };

  const checkEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return;

    post(vaultGatesVerifyEmailPath, { email }).catch(() => {
      setEmailTaken(true);
    });
  };

  const basicFormFieldChange = (field, newValue) => {
    if (field === 'email') {
      setEmailTaken(false);
      checkEmail(newValue);
    }

    setAccountFormFields({ ...accountFormFields, [field]: newValue });
  };

  const setInvestorTypesFromInput = (e) => {
    if (e.target.checked) {
      setAccountFormFields({
        ...accountFormFields,
        investorTypes: [...accountFormFields.investorTypes, e.target.value]
      });
    } else {
      setAccountFormFields({
        ...accountFormFields,
        investorTypes: accountFormFields.investorTypes.filter(typeKey => typeKey !== e.target.value)
      });
    }
  };

  if (loadingData) {
    return (
      <div className="text-center">
        <FontAwesomeIcon icon={faSpinner} size="2x" spin/>
      </div>
    );
  }

  const isCaNotSigned = steps.includes(CA_NOT_SIGNED);
  const isCollectInformation = steps.includes(COLLECT_INFORMATION);
  const isInvalidProfile = steps.includes(INVALID_PROFILE);
  const isRegistration = steps.includes(REGISTRATION);

  const appendFormData = (formData, baseKey, profileAttributesFields) => {
    const profileAttributes = pick(accountFormFields, profileAttributesFields);
    for (const [attribute, value] of Object.entries(snakecaseKeys(profileAttributes))) {
      formData.append(`${baseKey}[${attribute}]`, value);
    }
  };

  const submitData = (e) => {
    e.preventDefault();

    setErrors([]);
    setSaving(true);

    const formData = new FormData();
    const profileAttributesFields = ['firstName', 'lastName', 'phoneNumber', 'companyName', 'jobTitle',
      'investorTypes', 'countryId', 'email'];

    if (isRegistration) {
      profileAttributesFields.push('address', 'city', 'state', 'zip');
      appendFormData(formData, 'login_credential[profile_attributes]', profileAttributesFields);
      formData.append('login_credential[email]', accountFormFields.email);
      formData.append('login_credential[password]', accountFormFields.password);
      formData.append('login_credential[profile_attributes][primary_role]', primaryRole);
      formData.append('login_credential[profile_attributes][from_connect]', 'true');
    } else if (isCollectInformation || isInvalidProfile) {
      appendFormData(formData, 'profile', profileAttributesFields);
      formData.append('profile[primary_role]', primaryRole);
      formData.append('profile[from_connect]', 'true');
      if (isCollectInformation) formData.append('profile[is_fast_track]', 'true');
    }

    if (isCaNotSigned && caData.filePath) {
      formData.append('signed_ca_file', caFields.uploadedFile);
    } else if (isCaNotSigned) {
      formData.append('sign_ca', '1');
      if (caFields.checked) formData.append('email_ca', '1');
    }

    formData.append('invited_lead[email]', getQueryParameter('email'));
    formData.append('invited_lead[token]', getQueryParameter('lead_token'));

    post(vaultGatesFormPath, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((data) => {
      if (data.data.length === 0) {
        onNext(nextStep, true);
        return;
      }

      setSaving(false);
      setErrors(data.data);
    });
  };

  const uniqErrors = uniq(errors);

  const getDisplayText = () => {
    const textToDisplay = {};

    const headerSuffix = noDocs ? 'be notified for documents' : 'access secure documents';

    if (isCaNotSigned) {
      textToDisplay.headerText = `Sign CA to ${headerSuffix}`;
      textToDisplay.buttonText = 'Sign CA';
    }
    if (isCollectInformation || isRegistration || isInvalidProfile) {
      textToDisplay.headerText = `Complete the form below to ${headerSuffix}`;
      textToDisplay.buttonText = 'Submit Form';
    }

    return textToDisplay;
  };

  const { headerText, buttonText } = getDisplayText();

  const disableForm = isRegistration && emailTaken;

  return (
    <div>
      <Button className="d-none d-lg-block" variant="link" onClick={backToUserType}>
        <FontAwesomeIcon className="me-2" icon={faChevronLeft} size="lg"/>
        Change Role
      </Button>

      <div className="text-center mb-6 mt-2 text-branding">
        <FontAwesomeIcon icon={faLock} size="2x"/>
      </div>
      <h5 className="text-center mb-4"><strong>{headerText}</strong></h5>

      <form noValidate onSubmit={submitData}>
        {isCaNotSigned && (
          <CaContainer caData={caData} caFields={caFields} setCaFields={setCaFields}/>
        )}
        {(isCollectInformation || isRegistration || isInvalidProfile) && (
          <GateAccountFields
            disableForm={disableForm}
            emailTaken={emailTaken}
            errors={uniqErrors}
            formFields={accountFormFields}
            isInvalidProfile={isInvalidProfile}
            isRegistration={isRegistration}
            primaryRole={primaryRole}
            readonly={readonly}
            onFormFieldChange={basicFormFieldChange}
            onInvestorFieldChange={setInvestorTypesFromInput}
          />
        )}
        <Button
          className="w-100 mt-2"
          disabled={saving || readonly || disableForm}
          name="save"
          type="submit"
          variant="primary"
        >
          {saving ? 'Submitting ...' : buttonText}
        </Button>
      </form>
    </div>
  );
}

GateForm.propTypes = {
  nextStep: PropTypes.string.isRequired,
  noDocs: PropTypes.bool,
  previousStep: PropTypes.string.isRequired,
  primaryRole: PropTypes.string.isRequired,
  readonly: PropTypes.bool.isRequired,
  setPrimaryRole: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  vaultGatesFormPath: PropTypes.string.isRequired,
  vaultGatesProfilePath: PropTypes.string.isRequired,
  vaultGatesVerifyEmailPath: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
};

const mapDispatchToProps = { setPrimaryRole };
const mapStateToProps = ({ ui }) => ({ primaryRole: ui.primaryRole });
export default connect(mapStateToProps, mapDispatchToProps)(GateForm);
