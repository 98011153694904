import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import ManagedInput from 'components/form/managed_input';
import Controls from './controls';

export default function Investment({ profile, onBack, onNext, onValueChange }) {
  const submit = (e) => {
    e.preventDefault();
    onNext();
  };

  return (
    <form onSubmit={submit}>
      <div className="mb-2">
        <FontAwesomeIcon icon={faMagnifyingGlass} size="2x" />
      </div>
      <h5 className="mb-4">Tell us about your current investment search</h5>

      <div className="mb-3">
        <Form.Label>How active is your current investment search? *</Form.Label>
        <Form.Select
          autoFocus
          value={profile.currentInvestmentActivity || ''}
          onChange={e => onValueChange('currentInvestmentActivity', e.target.value)}
        >
          <option value="">Select level of activity</option>
          <option value="inactive">Inactive</option>
          <option value="passive">Passive</option>
          <option value="moderate">Moderate</option>
          <option value="active">Active</option>
        </Form.Select>
      </div>

      <div className="mb-3">
        <Form.Label>Are you in a 1031 Exchange process?</Form.Label>
        <Form.Check
          checked={!!profile.is1031}
          className="form-switch-lg"
          id="1031-toggle"
          label={profile.is1031 ? 'Yes' : 'No'}
          type="switch"
          onChange={e => onValueChange('is1031', e.target.checked)}
        />
      </div>

      {profile.is1031 && (
        <div className="mb-3">
          <Form.Label>What is your 1031 Exchange deadline?</Form.Label>
          <div>
            <ManagedInput
              className="form-control d-block"
              type="date"
              value={profile.expirationDate1031}
              onValueChange={value => onValueChange('expirationDate1031', value)}
            />
          </div>
        </div>
      )}

      <Controls
        disabled={!profile.currentInvestmentActivity}
        showNext
        onBack={onBack}
      />
    </form>
  );
}

Investment.propTypes = {
  profile: PropTypes.shape({
    companyName: PropTypes.string
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired
};
