import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setShowMap } from '../store/actions/ui';

import { Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faLocationDot } from '@fortawesome/free-solid-svg-icons';

export function MobileViewSwitcher({ setShowMap, showMap }) {
  return (
    <div className="position-fixed bottom-0 end-0 pe-4 pb-4 d-block d-lg-none">
      <Button variant="light" onClick={() => setShowMap(!showMap)}>
        {showMap && (
          <span>
            <FontAwesomeIcon className="me-2" icon={faList} />
            List
          </span>
        )}
        {!showMap && (
          <span>
            <FontAwesomeIcon className="me-2" icon={faLocationDot} />
            Map
          </span>
        )}
      </Button>
    </div>
  );
}

MobileViewSwitcher.propTypes = {
  setShowMap: PropTypes.func.isRequired,
  showMap: PropTypes.bool.isRequired
};

const mapStateToProps = ({ ui }) => ({ showMap: ui.showMap });

export default connect(mapStateToProps, { setShowMap })(MobileViewSwitcher);
