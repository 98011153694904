import { get, put, post } from 'helpers/api';

const rootPath = '/connect/listings';
const profilePath = '/profiles';

export function fetchMatch(id) {
  return get(`${rootPath}/${id}.json`);
}

export function updateMatch(id, body) {
  return put(`${rootPath}/${id}`, { boostMatch: body });
}

export function createMatch(saleListingId, requirementsSetId, body) {
  return post(`${rootPath}`, { saleListingId, requirementsSetId, boostMatch: body });
}

export function updateProfile(id, body) {
  return put(`${profilePath}/${id}`, body);
}
