import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';
import { faBookmark as farBookmark } from '@fortawesome/pro-regular-svg-icons';
import { updateConnectProperty } from '../../../../helpers/connect_api';

export default function PropertyBookmark({ id, bookmarked, buttonClass }) {
  const [isBookmarked, setIsBookmarked] = useState(bookmarked);

  useEffect(() => {
    const handlePropertyBookmarked = (e) => {
      if (e.detail.id == id) {
        updateConnectProperty(id, { bookmarked: !isBookmarked }).then(() => setIsBookmarked(!isBookmarked));
      }
    };

    window.addEventListener('propertyBookmark', handlePropertyBookmarked);

    return () => {
      window.removeEventListener('propertyBookmark', handlePropertyBookmarked);
    };
  }, [isBookmarked]);

  const toggleBookmark = () => {
    window.dispatchEvent(new CustomEvent('propertyBookmark', { detail: { id: id } }));
  };

  const bookmarkTooltip = (
    <Tooltip id={`bookmark-tooltip-${id}`}>
      Bookmark
    </Tooltip>
  );

  return (
    <OverlayTrigger overlay={bookmarkTooltip} placement="auto">
      <Button
        variant={`text-primary ${buttonClass}`}
        onClick={toggleBookmark}
      >
        <FontAwesomeIcon icon={isBookmarked ? faBookmark : farBookmark} />
      </Button>
    </OverlayTrigger>
  );
}

PropertyBookmark.propTypes = {
  bookmarked: PropTypes.bool.isRequired,
  buttonClass: PropTypes.string,
  id: PropTypes.number.isRequired,
};
