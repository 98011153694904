import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Container, Row } from 'react-bootstrap-5';
import { Provider } from 'react-redux';

import configureStore from './store/configure';
import Tasks from './tasks';
import NotesCard from './notes';
import { PROPERTY_MODEL } from '../../constants';
import Overview from './overview';
import ActivitySection from './activity_section';
import ActivityHistoryCard from '../contacts/activity_history_card';

export default function CallListItem(
  { activities, callListId, callTemplates, trialUser, recordId, recordType, insightsPropertyId }
) {
  const store = useMemo(() => configureStore({ activities, owners: null, insightsPropertyId }), []);
  const hasProperty = recordType === PROPERTY_MODEL;

  return (
    <Provider store={store}>
      <Container fluid>
        <Row className="g-3">
          {hasProperty && (
            <Col lg={5}>
              <Overview insightsPropertyId={insightsPropertyId}/>
            </Col>
          )}
          <Col lg={hasProperty ? 7 : 12}>
            <Row className="g-3">
              <Col xs={hasProperty ? 12 : 7}>
                <ActivitySection
                  callListId={callListId}
                  callTemplates={callTemplates}
                  trialUser={trialUser}
                  recordId={recordId}
                  recordType={recordType}
                />
              </Col>
              <Col xs={hasProperty ? 12 : 5}>
                <Row className="g-3">
                  <Col xl={hasProperty ? 6 : 12}>
                    <Card>
                      <Card.Header>
                        <h5 className="fw-bolder mb-0">Activity History</h5>
                      </Card.Header>
                      <ActivityHistoryCard />
                    </Card>
                  </Col>
                  <Col className="d-flex flex-column gap-3" xl={hasProperty ? 6 : 12}>
                    <NotesCard
                      recordId={recordId}
                      recordType={recordType}
                    />
                    <Tasks
                      callListId={callListId}
                      callTemplates={callTemplates}
                      recordId={recordId}
                      recordType={recordType}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Provider>
  );
}

CallListItem.propTypes = {
  activities: PropTypes.array.isRequired,
  callListId: PropTypes.number.isRequired,
  callTemplates: PropTypes.array.isRequired,
  insightsPropertyId: PropTypes.number,
  trialUser: PropTypes.bool.isRequired,
  recordId: PropTypes.number.isRequired,
  recordType: PropTypes.string.isRequired
};
