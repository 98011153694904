import React from 'react';
import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap-5';

export default function PopoverSelect({ active, children, label, selectedLabel }) {
  return (
    <OverlayTrigger
      overlay={
        <Popover className="popover-select" id={`popover-positioned-bottom`}>
          <Popover.Body className="overflow-auto" style={{ maxHeight: '500px' }}>
            {children}
          </Popover.Body>
        </Popover>
      }
      placement={'bottom'}
      rootClose
      trigger="click"
    >
      <Form.Group>
        <div className={`form-select as-button clickable text-truncate ${active || !!selectedLabel ? 'active' : ''}`}>
          {selectedLabel || label}
        </div>
      </Form.Group>
    </OverlayTrigger>
  );
}

PopoverSelect.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  selectedLabel: PropTypes.string
};
