import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { findOffMarketPropertyDetails } from '../../helpers/connect_api';
import { Spinner } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesPacking } from '@fortawesome/pro-solid-svg-icons';
import PropertyRecordRow from './property_record_row';
import EmptyState from '../empty_state';

export default function PropertyRecords({ insightsPropertyId }) {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    findOffMarketPropertyDetails(insightsPropertyId)
      .then(res => setRecords(res.propertyHistory || []))
      .catch(() => {
        // errors
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  if (records.length === 0) {
    return (
      <EmptyState icon={faBoxesPacking} title={'No Records Found'} />
    );
  }

  return (
    <React.Fragment>
      <div className="d-flex align-items-center gap-2 mb-4">
        <FontAwesomeIcon className="text-secondary" icon={faBoxesPacking} />
        <h6 className="my-0 fw-bolder">Property Records</h6>
      </div>
      <div className="d-flex flex-column gap-6">
        {records.map((record, idx) => (
          <PropertyRecordRow collapsed={idx === 0 ? false : true} key={idx} propertyRecord={record} />
        ))}
      </div>
    </React.Fragment>
  );
}

PropertyRecords.propTypes = {
  insightsPropertyId: PropTypes.number.isRequired
};
