import { fetchContactOwnedProperties } from 'bundles/connect/helpers/connect_api';
import { deleteOwnershipRequest } from '../../../../helpers/connect_api';

export const SET_OWNED_PROPERTIES = 'SET_OWNED_PROPERTIES';
export const DELETE_OWNED_PROPERTY = 'DELETE_OWNED_PROPERTY';

export function setOwnedProperties(contactId) {
  return (dispatch) => {
    return fetchContactOwnedProperties(contactId).then((results) => {
      dispatch({
        type: SET_OWNED_PROPERTIES,
        payload: results
      });
    });
  };
}

export function deleteOwnedProperty(insightsPropertyId, ownershipId) {
  return (dispatch) => {
    return deleteOwnershipRequest(insightsPropertyId, ownershipId).then(() => {
      dispatch({
        type: DELETE_OWNED_PROPERTY,
        payload: { ownershipId }
      });
    });
  };
}
