import React from 'react';
import PropTypes from 'prop-types';

import _ from 'helpers/no_conflict_lodash';

import { Col, Row, Checkbox, Radio, ControlLabel, FormGroup } from 'react-bootstrap';

export default class FilterCheckboxGroup extends React.Component {
  static propTypes = {
    checkAllIfNone: PropTypes.bool,
    children: PropTypes.node,
    defaultKey: PropTypes.string,
    inline: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      className: PropTypes.any,
      id: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.any
    })).isRequired,
    selected: PropTypes.any,
    singleSelection: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    defaultKey: ''
  }

  checkAll = () => {
    const { selected, checkAllIfNone } = this.props;
    return checkAllIfNone && Array.isArray(selected) && selected.length === 0;
  }

  handleChange = (e) => {
    if (!this.checkAll()) return this.props.onChange(e);

    e.target.checked = true;
    this.props.onChange(e);
  }

  render() {
    return (
      <FormGroup className="mb-0">
        {this.props.label && <ControlLabel>{this.props.label}</ControlLabel>}
        <Row>
          <Col className={this.props.inline ? 'inline-filter-checkbox-group' : 'filter-checkbox-group'} xs={12}>
            {this.renderOptions()}
            {this.props.children}
          </Col>
        </Row>
      </FormGroup>
    );
  }

  isChecked(value) {
    if (this.props.singleSelection) {
      return this.props.selected === value;
    } else {
      return _.includes(this.props.selected || [], value);
    }
  }

  renderOptions() {
    const Control = this.props.singleSelection ? Radio : Checkbox;
    return _.map(this.props.options, (option, i) => (
      <div className="filter-checkbox" key={this.props.defaultKey ? this.props.defaultKey + option.id || i : i}>
        <Control
          aria-label={option.value}
          checked={this.checkAll() || this.isChecked(option.value)}
          className={option.className}
          inline={true}
          name={`${option.value}_check`}
          value={option.value}
          onChange={this.handleChange}
        >
          {option.label}
        </Control>
      </div>
    ));
  }
}
