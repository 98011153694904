import {
  createValuationAdjustment,
  deleteValuationAdjustment,
  fetchValuationAdjustments,
  updateValuationAdjustment,
} from '../../../../helpers/connect_api';

export const LOAD_VALUATION_ADJUSTMENTS = 'LOAD_VALUATION_ADJUSTMENTS';
export const SET_VALUATION_ADJUSTMENTS = 'SET_VALUATION_ADJUSTMENTS';
export const RESET_VALUATION_ADJUSTMENTS = 'RESET_VALUATION_ADJUSTMENTS';

export function loadValuationAdjustments() {
  return async (dispatch) => {
    const valuationAdjustments = await fetchValuationAdjustments();

    dispatch({
      type: LOAD_VALUATION_ADJUSTMENTS,
      payload: valuationAdjustments,
    });
  };
}

export function setValuationAdjustments(body) {
  return async (dispatch) => {
    const valuationAdjustment = body?.id
      ? await updateValuationAdjustment(body)
      : await createValuationAdjustment(body);

    dispatch({
      type: SET_VALUATION_ADJUSTMENTS,
      payload: valuationAdjustment,
    });
  };
}

export function resetValuationAdjustments(valuationAdjustment) {
  return (dispatch) => {
    deleteValuationAdjustment(valuationAdjustment.id);

    dispatch({
      type: RESET_VALUATION_ADJUSTMENTS,
      payload: valuationAdjustment.insightsPropertyId,
    });
  };
}
