import React, { useEffect, useMemo, useState } from 'react';

import { offMarketPropertyUrl, exclusiveListingUrl } from 'bundles/connect/helpers/connect_api';
import DetailOffcanvas from './detail_offcanvas';
import { addUrlParam, clearUrlParam, getUrlParam } from '../helpers/url_helper';
import { trackEvent } from '../helpers/google_analytics_helpers';

export const OFF_MARKET_URL_PARAM = 'property_id';
export const EXCLUSIVE_LISTING_URL_PARAM = 'exclusive_listing_id';

export default function PropertyOffcanvasContainer() {
  const [propertyId, setPropertyId] = useState(() => getUrlParam(OFF_MARKET_URL_PARAM));
  const [listingId, setListingId] = useState(() => getUrlParam(EXCLUSIVE_LISTING_URL_PARAM));

  useEffect(() => {
    const offMarketPropertyId = getUrlParam(OFF_MARKET_URL_PARAM);
    if (offMarketPropertyId) {
      setPropertyId(offMarketPropertyId);
      clearUrlParam(EXCLUSIVE_LISTING_URL_PARAM);
    } else {
      const exclusiveListingParam = getUrlParam(EXCLUSIVE_LISTING_URL_PARAM);
      if (exclusiveListingParam) setListingId(exclusiveListingParam);
    }
  }, []);

  // when user navigates back/forward, check the URL and reassign the propertyId
  useEffect(() => {
    const listener = (e) => {
      setPropertyId(e.state?.propertyId);
      setListingId(e.state?.listingId);
    };
    window.addEventListener('popstate', listener);

    return () => window.removeEventListener('popstate', listener);
  }, []);

  useEffect(() => {
    if (propertyId) {
      clearUrlParam(EXCLUSIVE_LISTING_URL_PARAM);
      trackEvent('connect_search_off_market_property_modal_open', { propertyId });
      if (history.state?.propertyId !== propertyId) {
        if (!history.state?.propertyId) {
          history.pushState({ propertyId }, '', addUrlParam(OFF_MARKET_URL_PARAM, propertyId));
        } else {
          history.replaceState({ propertyId }, '', addUrlParam(OFF_MARKET_URL_PARAM, propertyId));
        }
      }
    } else if (listingId) {
      clearUrlParam(OFF_MARKET_URL_PARAM);
      trackEvent('connect_search_exclusive_listing_modal_open', { listingId });
      if (history.state?.listingId !== listingId) {
        if (!history.state?.listingId) {
          history.pushState({ listingId }, '', addUrlParam(EXCLUSIVE_LISTING_URL_PARAM, listingId));
        } else {
          history.replaceState({ listingId }, '', addUrlParam(EXCLUSIVE_LISTING_URL_PARAM, listingId));
        }
      }
    } else {
      clearUrlParam(OFF_MARKET_URL_PARAM);
      clearUrlParam(EXCLUSIVE_LISTING_URL_PARAM);
    }
  }, [propertyId, listingId]);

  useEffect(() => {
    const showPropertyDetails = (event) => {
      const { eventName, payload } = event?.data || {};

      if (['pn-property-details', 'off_market_property_show'].includes(eventName)) {
        setPropertyId(payload.id);
      }

      if (eventName == 'exclusive_listing_show') {
        setListingId(payload.id);
      }
    };

    window.addEventListener('message', showPropertyDetails);

    return () => window.removeEventListener('message', showPropertyDetails);
  }, []);

  const src = useMemo(() => {
    if (propertyId) return offMarketPropertyUrl(propertyId);
    if (listingId) return exclusiveListingUrl(listingId);
  }, [propertyId, listingId]);

  return <DetailOffcanvas src={src} onHide={() => history.go(-1)} />;
}
