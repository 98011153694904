import React from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import UnstyledDropdownToggle from 'components/bootstrap_extensions/bs5/unstyled_dropdown_toggle';
import { deleteNote } from '../../helpers/connect_api';

export default function PropertyNoteCard({ note, onEdit, onRemove }) {
  const handleRemove = () => {
    deleteNote(note.id).then(() => {
      onRemove(note.id);
    });
  };

  return (
    <Card className="border-0">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <div dangerouslySetInnerHTML={{ __html: note.body }} />
            <div className="small text-muted pt-1">
              Created {new Date(note.createdAt).toLocaleDateString('en-US')}
              {(note.updatedAt != note.createdAt) &&
                ` - Updated ${new Date(note.updatedAt).toLocaleDateString('en-US')}`
              }
            </div>
          </div>
          <Dropdown>
            <Dropdown.Toggle as={UnstyledDropdownToggle} className="text-muted">
              <FontAwesomeIcon className="clickable px-2" icon={faEllipsisVertical}/>
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-0">
              <Dropdown.Item className="border-top-0" onClick={onEdit}>
                <FontAwesomeIcon className="me-2" fixedWidth icon={faPencil}/>
                Edit
              </Dropdown.Item>
              <Dropdown.Item className="border-bottom-0" onClick={handleRemove}>
                <FontAwesomeIcon className="me-2" fixedWidth icon={faTrash}/>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Body>
    </Card>
  );
}

PropertyNoteCard.propTypes = {
  note: PropTypes.shape({
    body: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func
};
