import {
  createOwnershipRequest,
  deleteOwnershipRequest,
  updateContactRequest,
  updateOwnership
} from '../../../../helpers/connect_api';

export const ADD_OWNERSHIP = 'ADD_OWNERSHIP';
export const SET_OWNERS = 'SET_OWNERS';
export const SET_VERIFIED_OWNER = 'SET_VERIFIED_OWNER';
export const SET_OWNER_CONTACT_METHOD_SCORE = 'SET_OWNER_CONTACT_METHOD_SCORE';
export const DELETE_OWNERSHIP = 'DELETE_OWNERSHIP';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';

export function setOwners(owners) {
  return { type: SET_OWNERS, payload: owners };
}

export function setVerifiedOwner(ownerId, verified) {
  return (dispatch, getState) => {
    const { insightsPropertyId } = getState();
    return updateOwnership(insightsPropertyId, ownerId, { verified }).then(() => {
      dispatch({
        type: SET_VERIFIED_OWNER,
        payload: { ownerId, verified }
      });
    });
  };
}

export function setOwnerContactMethodScore(
  owner,
  contactMethodKey,
  contactMethodId,
  newScore
) {
  return (dispatch, getState) => {
    const { insightsPropertyId } = getState();
    return updateOwnership(
      insightsPropertyId,
      owner.id,
      {
        contactAttributes: {
          id: owner.contact.id,
          [`${contactMethodKey}Attributes`]: [{ id: contactMethodId, connectRankScore: newScore }]
        }
      }
    ).then(() => {
      dispatch({
        type: SET_OWNER_CONTACT_METHOD_SCORE,
        payload: { ownerId: owner.id, contactMethodKey, contactMethodId, newScore }
      });
    });
  };
}

export function deleteOwnership(ownerId) {
  if (confirm('Delete this contact?')) {
    return (dispatch, getState) => {
      const { insightsPropertyId } = getState();
      return deleteOwnershipRequest(insightsPropertyId, ownerId).then(() => {
        dispatch({
          type: DELETE_OWNERSHIP,
          payload: { ownerId }
        });
      });
    };
  }
}

export function createOwnership(insightPropertyId, contactId) {
  return (dispatch) => {
    return createOwnershipRequest(insightPropertyId, contactId).then((result) => {
      dispatch({
        type: ADD_OWNERSHIP,
        payload: result['connect/ownership']
      });
    });
  };
}

export function updateContact(contactId, params) {
  return (dispatch) => {
    return updateContactRequest(contactId, params).then((data) => {
      dispatch({
        type: UPDATE_CONTACT,
        payload: data
      });
    });
  };
}
